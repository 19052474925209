import { Routes } from "react-router-dom";

import AdminRoutes from "./AdminRoutes";
import SellerRoutes from "./SellerRoutes";
import PublicRoutes from "./PublicRoutes";

const AppRoutes = (props: any) => {
  const { isAuthenticated, isVerifying } = props;

  return (
    <Routes>
      {AdminRoutes()}
      {SellerRoutes()}
      {PublicRoutes()}
    </Routes>
  );
};

export default AppRoutes;
