import React from "react";
import ErrorScreen from "../components/ErrorScreen/ErrorScreen";
import { withErrorBoundary } from "./ErrorBoundary";

const UnprotectedRoute = ({ children }: { children: JSX.Element }) => {
  return withErrorBoundary(children, {
    fallback: <ErrorScreen />,
  });
};

export default UnprotectedRoute;
