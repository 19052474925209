import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
const ImageGallery: React.FC<CustomModalProps> = ({
  handleClose,
  open,
  submitAction,
}) => {
  const listing = useSelector((state: RootState) => state.listing.listing);
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="bg-white h-[90vh] w-[90vw] sm:w-[80vw] mx-auto mt-[5vh] overflow-y-scroll outline-none">
        <div className="flex sticky top-0 bg-white items-center py-[26px] px-6 sm:px-[100px] text-primary card-shadow">
          <img src="/assets/images/chevron-left.svg" alt="" />
          <button className="ml-[14px] mr-auto" onClick={handleClose}>
            Back to Listing
          </button>
          <div className="hidden sm:contents">
            <div className="flex items-center gap-x-[18px]">
              <img src="/assets/images/heart.svg" alt="" />
              <div className="flex items-center gap-x-[6px]">
                <img src="/assets/images/flag.svg" alt="" />
                <span>Report</span>
              </div>
              <div className="flex items-center gap-x-[6px]">
                <img src="/assets/images/print.svg" alt="" />
                <span>Print</span>
              </div>
              <div className="flex items-center gap-x-[6px]">
                <img src="/assets/images/share.svg" alt="" />
                <span>Share</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-6 sm:gap-y-[35px] px-6 sm:px-10 pb-10 lg:px-[100px] mt-[30px]">
          {listing?.files.map((file) => {
            return (
              <div className="h-[224px] sm:h-[447px] rounded-lg">
                <img
                  src={file.file}
                  className="w-full h-full object-cover rounded-lg"
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ImageGallery;
