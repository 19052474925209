import { useState } from "react";
import { RatingStarOff, RatingStarOn } from "./LayoutIcons";

const ListingRating = ({
  rating,
  disabled = false,
  size,
  onChange,
}: {
  rating: number;
  disabled?: boolean;
  size?: number;
  onChange?: (newRating: number) => void;
}) => {
  const [updatedRating, setUpdatedRating] = useState(rating);
  const [tempRating, setTempRating] = useState(0);

  const minRating = 1;
  const maxRating = 5;

  if (isNaN(rating)) {
    rating = minRating;
  }
  rating = Math.round(rating);
  if (rating < minRating) {
    rating = minRating;
  } else if (rating > maxRating) {
    rating = maxRating;
  }

  return (
    <div className="rating-group" onMouseLeave={() => setTempRating(0)}>
      {new Array(maxRating).fill(null).map((unit, index: number) => (
        <div
          key={index}
          style={{ cursor: disabled ? "default" : "pointer" }}
          // onClick={() => !disabled && onChange && onChange(index)}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            !disabled && setUpdatedRating(index + 1);
          }}
          onMouseEnter={() => !disabled && setTempRating(index + 1)}
        >
          {index + 1 <= (tempRating || updatedRating) ? (
            <RatingStarOn
              key={`index-on-${index}`}
              style={{ ...(size ? { width: size } : {}) }}
            />
          ) : (
            <RatingStarOff
              key={`index-on-${index}`}
              style={{ ...(size ? { width: size } : {}) }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ListingRating;
