import { Modal } from "@mui/material";
import React, { useState } from "react";
import { CustomModalProps } from "../../../types";

interface Props extends CustomModalProps {
    email: string;
    setEmail: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const ForgottenPassword: React.FC<Props> = ({
  handleClose,
  open,
  submitAction,
  email,
  setEmail
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container">
            <h2 className="modal__title">Forgotten Password</h2>
            <span className="modal__subtitle">Please verify your email address</span>
        <div className="">
          <label
            htmlFor="email"
            className="custom__label"
          >
            Email
          </label>
          <input
            value={email}
            onChange={setEmail}
            id="email"
            type="email"
            className="custom__input"
          />
        </div>
        <button onClick={submitAction} className="modal__action">Next</button>
      </div>
    </Modal>
  );
};

export default ForgottenPassword;
