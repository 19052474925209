import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import statesData from "../../data/states.json";

const StateModal: React.FC<CustomModalProps> = ({ handleClose, open }) => {
  const { states } = statesData;
  const firstColumn = searchByKeys(states, ["A", "D", "G", "K"]);
  const secondColumn = searchByKeys(states, ["B", "E", "I", "L", "P"]);
  const thirdColumn = searchByKeys(states, ["C", "F", "J", "N", "R", "Y"]);

  return (
    <Modal onClose={handleClose} open={open}>
      <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] w-[90%] sm:w-[1124px] outline-none">
        <div className=" bg-[#E8EDE9] h-[85vh] overflow-y-auto sm:h-fit w-full rounded-[10px] py-10 px-6 sm:px-[50px]">
          <div className="w-full hidden sm:flex justify-between">
            <div className="flex flex-col gap-y-[45px]">
              {firstColumn.map((entry) => {
                const stateInitial = Object.keys(entry);
                const stateList = Object.values(entry)[0] as string[];
                return (
                  <div className="flex gap-x-[25px]">
                    <h2 className="h2">{stateInitial}</h2>
                    <div className="bg-white card-shadow rounded w-[242px]">
                      {stateList.map((stateName) => (
                        <div className="flex justify-between py-[10px] px-[15px]">
                          <span className="text-[16px]">
                            {stateName}{" "}
                            <span className="text-primary/50">
                              (12 results)
                            </span>
                          </span>
                          <img src="/assets/images/chevron-right.svg" alt="" />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col gap-y-[45px]">
              {secondColumn.map((entry) => {
                const stateInitial = Object.keys(entry);
                const stateList = Object.values(entry)[0] as string[];
                return (
                  <div className="flex gap-x-[25px]">
                    <h2 className="h2">{stateInitial}</h2>
                    <div className="bg-white card-shadow rounded w-[242px]">
                      {stateList.map((stateName) => (
                        <div className="flex justify-between py-[10px] px-[15px]">
                          <span className="text-[16px]">
                            {stateName}{" "}
                            <span className="text-primary/50">
                              (12 results)
                            </span>
                          </span>
                          <img src="/assets/images/chevron-right.svg" alt="" />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col gap-y-[45px]">
              {thirdColumn.map((entry) => {
                const stateInitial = Object.keys(entry);
                const stateList = Object.values(entry)[0] as string[];
                return (
                  <div className="flex gap-x-[25px]">
                    <h2 className="h2">{stateInitial}</h2>
                    <div className="bg-white card-shadow rounded w-[242px]">
                      {stateList.map((stateName) => (
                        <div className="flex justify-between py-[10px] px-[15px]">
                          <span className="text-[16px]">
                            {stateName}{" "}
                            <span className="text-primary/50">
                              (12 results)
                            </span>
                          </span>
                          <img src="/assets/images/chevron-right.svg" alt="" />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-y-6 sm:hidden">
            {states.map((entry) => {
              const stateInitial = Object.keys(entry);
              const stateList = Object.values(entry)[0] as string[];
              return (
                <div className="">
                  <h2 className="h2">{stateInitial}</h2>
                  <div className="bg-white card-shadow rounded w-full">
                    {stateList.map((stateName) => (
                      <div className="flex justify-between py-[10px] px-[15px]">
                        <span className="text-[16px]">
                          {stateName}
                          <span className="text-primary/50">(12 results)</span>
                        </span>
                        <img src="/assets/images/chevron-right.svg" alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StateModal;

function searchByKeys(array: any[], keys: string[]) {
  return array.filter((obj) => keys.some((key) => key in obj));
}
