import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../../types";

interface Props extends CustomModalProps {
    propertyName: string,
    location: string,
}
const PhysicalTour: React.FC<Props> = ({ handleClose, open, submitAction, propertyName, location }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="payment-modal">
        <p className="payment-modal__title">Payment for Physical Tour</p>
        <div className="payment-modal__price">
          <p className="dollar">$10</p>
          <p className="naira">(N17,000)</p>
        </div>
        <p className="payment-modal__field">Property Name:</p>
        <p className="payment-modal__value">{propertyName}</p>
        <div className="mt-[41px] mb-[21px]">
          <p className="payment-modal__field">Location:</p>
          <p className="payment-modal__value">{location}</p>
        </div>
        <p className="payment-modal__info w-[90%] mx-auto">
          A confirmation email will be sent to you upon successful payment. <br /> <br /> Note
          that this payment is non-refundable..
        </p>
        <button onClick={submitAction} className="filled-button w-full">Click Here To Pay</button>
      </div>
    </Modal>
  );
};

export default PhysicalTour;
