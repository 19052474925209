import React, { useState } from "react";
import UserDropdown from "../modals/UserDropdown";
import SupportDropdown from "../../Seller/modals/SupportDropdown";
import Marquee from "react-fast-marquee";
import MobileNav from "../../Seller/components/MobileNav";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const HomeLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div>
      <Navbar />
      {children}
      <div className="bg-[#BCD5BE] py-[22px] overflow-x-hidden mt-10">
        <Marquee autoFill speed={100} className="">
          <div className="flex space-x-14 pr-14">
            <img src="/assets/images/brain-hammers.png" alt="" />
            <img src="/assets/images/mixta-africa.png" alt="" />
            <img src="/assets/images/post-service.png" alt="" />
            <img src="/assets/images/modern-shelter.png" alt="" />
            <img src="/assets/images/urban-shelter.png" alt="" />
            <img src="/assets/images/brain-hammers.png" alt="" />
            <img src="/assets/images/mixta-africa.png" alt="" />
            <img src="/assets/images/post-service.png" alt="" />
            <img src="/assets/images/modern-shelter.png" alt="" />
            <img src="/assets/images/urban-shelter.png" alt="" />
          </div>
        </Marquee>
      </div>
      <footer className="py-[30px] px-4 sm:px-10 lg:px-[175px]">
        <section className="sm:grid grid-cols-7">
          <div className="col-span-3 lg:col-span-2 flex justify-between items-center sm:block">
            <div className="flex flex-row sm:flex-col items-center w-fit text-[17px] font-semibold italic text-primary">
              <img src="/assets/images/logo.svg" className="h-[50px]" alt="" />
              <div className="flex flex-col items-center sm:contents">
                <p className="">HOME</p>
                <div className="flex items-center space-x-[3px]">
                  <div className="w-[26px] bg-custom-gradient h-[2px]" />
                  <span className="gradient-text text-[10px]">&</span>
                  <div className="w-[26px] bg-custom-gradient h-[2px]" />
                </div>
                <p>ABROAD</p>
              </div>
            </div>
            <div className="flex items-center space-x-4 mt-6">
              <img src="/assets/images/x.svg" alt="" />
              <img src="/assets/images/instagram.svg" alt="" />
              <img src="/assets/images/youtube.svg" alt="" />
              <img src="/assets/images/linkedin.svg" alt="" />
            </div>
          </div>
          <div className="col-span-5 flex flex-col lg:flex-row gap-x-[100px] justify-end gap-y-6 mt-8 sm:mt-0">
            <div>
              <span className="subheading-20">Platform</span>
              <ul className="mt-5 sm:mt-7 flex flex-wrap sm:flex-col gap-x-8 gap-y-3 body-description">
                <Link to="/about">
                  <li>About Us</li>
                </Link>
                <Link to="/faqs">
                  <li>FAQs</li>
                </Link>
                <li>Contact Us</li>
              </ul>
            </div>
            <div>
              <span className="subheading-20">Support</span>
              <ul className="mt-5 sm:mt-7 flex flex-wrap sm:flex-col gap-x-8 gap-y-3 body-description">
                <li>Terms and Conditions</li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </div>
        </section>
        <div className="flex flex-wrap px-4 justify-center gap-x-4 mt-[50px] h3">
          <span>This is an initiative of</span>
          <a href="https://nidcom.gov.ng">
            <img src="/assets/images/nidcom.png" alt="" />
          </a>
          <span>powered by</span>
          <a href="https://sydani.org/">
            <img src="/assets/images/sydani.png" alt="" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default HomeLayout;

export const Navbar = () => {
  // @ts-ignore

  const isAuthenticated = localStorage.getItem("access_token");
  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);
  // @ts-ignore
  const source = user ? user["user-types"][0].type : "unauthenticated";

  return (
    <nav className="flex h-[94px] items-center px-4 sm:px-8 lg:px-[100px] text-textBlack font-medium text-[16px] card-shadow">
      <Link to="/">
        <div className="flex items-center space-x-[7px]">
          <img className="h-11" src="/assets/images/logo.svg" alt="" />
          <div className="flex flex-col items-center text-[15.28px] font-semibold italic text-primary">
            <p>HOME</p>
            <div className="flex items-center space-x-[3px]">
              <div className="w-[26px] bg-custom-gradient h-[2px]" />
              <span className="gradient-text text-[10px]">&</span>
              <div className="w-[26px] bg-custom-gradient h-[2px]" />
            </div>
            <p>ABROAD</p>
          </div>
        </div>
      </Link>
      <div className="hidden sm:contents">
        <ul className="nav-links">
          <Link to="/property-search">
            <li className="nav-link">Buy Now</li>
          </Link>
          <div className="flex px-[15px] flex-col items-center">
            <span className="text-[10px] text-red-500">Coming soon</span>
            <li className="">Mortgage</li>
          </div>
          <div className="flex px-[15px] flex-col items-center">
            <span className="text-[10px] text-red-500">Coming soon</span>
            <li className="">Installment</li>
          </div>
          <Link to="/faqs">
            <li className="nav-link">FAQs</li>
          </Link>
        </ul>
        <div className="ml-9 mr-5 hidden lg:block relative">
          <SupportDropdown />
        </div>
        {/* <img
          className="hidden lg:block mr-10 ml-auto sm:ml-0"
          src="/assets/images/cart.svg"
          alt=""
        /> */}
        {isAuthenticated && (
          <>
            <div className="hidden sm:block h-10 w-10 rounded-full bg-textGrey"></div>
            <div className="hidden sm:block">
              <UserDropdown />
            </div>
          </>
        )}
        {!isAuthenticated && (
          <div className="flex gap-x-[25px] ml-6 lg:ml-0">
            <Link to="/sign-in">
              <button className="outline-button">SIGN IN</button>
            </Link>
            <Link to="/sign-up" className="hidden lg:block">
              <button className="filled-button">SIGN UP</button>
            </Link>
          </div>
        )}
      </div>
      <div className="sm:hidden ml-auto">
        {/* @ts-ignore  */}
        <MobileNav source={source} />
      </div>
    </nav>
  );
};
