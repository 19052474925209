import * as React from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {
  IAPIResponse,
  IFilterFetch,
  IListings,
} from "../../../../../../common/utils/interfaces";
import CustomTable, {
  HeadCell,
} from "../../../../../../common/components/CustomTable/CustomTable";
import { getMyListings } from "../../../../../../../apis/listings/sellers";
import CustomBadge from "../../../../../../common/components/CustomBadge";
import { MoreHoriz } from "@mui/icons-material";

type Props = {
  setDetailData: (arg: IListings) => void;
  filter: IFilterFetch;
};

const testt = [
  {
    name: "My Name 1",
    location: "#1, Jimoh Road, Aba",
    marketStatus: "pending",
    purchaseStatus: "pending",
  },
  {
    name: "My Name 2",
    location: "#2, Jimoh Road, Aba",
    marketStatus: "pending",
    purchaseStatus: "pending",
  },
  {
    name: "My Name 3",
    location: "#3, Jimoh Road, Aba",
    marketStatus: "pending",
    purchaseStatus: "pending",
  },
  {
    name: "My Name 4",
    location: "#4, Jimoh Road, Aba",
    marketStatus: "pending",
    purchaseStatus: "pending",
  },
];

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "marketStautus",
    numeric: false,
    disablePadding: false,
    label: "Market Status",
  },
  {
    id: "purchaseStautus",
    numeric: false,
    disablePadding: false,
    label: "Purchase Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

const MyPropertiesTable: React.FC<Props> = ({
  setDetailData,
  filter,
}: Props) => {
  const [tableData, setTableData] = React.useState<IListings[]>([]);

  const tableTitle = { mainText: "My Properties", subText: "properties" };

  React.useEffect(() => {
    fetchTableData();
  }, [filter]);

  const fetchTableData = async () => {
    const result: IAPIResponse | undefined = await getMyListings(filter);
    if (result) {
      const { data, count } = result;
      setTableData(data);
    }
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const selectData = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    selectedData: IListings
  ) => {
    evt.stopPropagation();

    setDetailData(selectedData);
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: any;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.name}
      </TableCell>
      <TableCell align="left">{row.location}</TableCell>
      <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.marketStautus)}>
          {row.marketStautus}
        </CustomBadge>
      </TableCell>
      <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.purchaseStautus)}>
          {row.purchaseStautus}
        </CustomBadge>
      </TableCell>
      <TableCell align="center">
        <MoreHoriz />
      </TableCell>
    </>
  );

  return (
    <CustomTable
      tableTitle={tableTitle}
      headCells={headCells}
      rows={tableData}
      // rows={testt}
      mappedTableRows={MappedTableRows}
      additionalInfomation={
        <>
          Click on <MoreHoriz /> to access authorised information about each
          property
        </>
      }
    />
  );
};

export default MyPropertiesTable;
