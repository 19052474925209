import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import CustomBadge from "../../../../common/components/CustomBadge";
import CustomTable, {
  HeadCell,
} from "../../../../common/components/CustomTable/CustomTable";
import {
  IAPIResponse,
  IFilterFetch,
  ISellerListing,
} from "../../../../common/utils/interfaces";
import { getSellersList } from "../../../../../apis/admin/sellers";

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "properties",
    numeric: true,
    disablePadding: false,
    label: "Listed Properties",
  },
  {
    id: "regNo",
    numeric: false,
    disablePadding: false,
    label: "REG NO",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Verification Status",
    align: "center",
  },
];

const DashboardTable = ({ filter }: { filter: IFilterFetch }) => {
  const [tableData, setTableData] = useState<ISellerListing[]>([]);

  useEffect(() => {
    fetchTableData();
  }, [filter]);

  const fetchTableData = async () => {
    const result: IAPIResponse | undefined = await getSellersList(filter);
    if (result) {
      const { data, count } = result;
      setTableData(data);
    }
  };
  const data: ISellerListing[] = [];

  const tableTitle = {
    mainText: "Developers",
    subText: filter.search ? `results found` : `developers`,
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: ISellerListing;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {`${row.first_name} ${row.last_name}`}
      </TableCell>
      <TableCell align="right">* * * *</TableCell>
      <TableCell align="right">* * * *</TableCell>
      <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.status)}>{row.status}</CustomBadge>
      </TableCell>
    </>
  );

  return (
    <CustomTable
      tableType="section-table"
      tableTitle={tableTitle}
      headCells={headCells}
      rows={tableData}
      mappedTableRows={MappedTableRows}
      hideChangePerPage
    />
  );
};

export default DashboardTable;
