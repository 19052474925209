import { useLocation, useNavigate } from "react-router-dom";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, ArrowBackIos, Person } from "@mui/icons-material";

import SellerDashboardLayout from "../../commonComponents/DashboardLayout";
import {
  getFeaturesList,
  getPropertyTypesList,
} from "../../../../apis/listings/general";
import CustomUpload from "./components/CustomUpload";
import UploadedItem from "./components/UploadedItem";
import {
  categorizeFeatures,
  convertInputDataToFormData,
} from "../../../common/utils/helpers";
import {
  getMyListingDetails,
  postNewListing,
  updateListing,
} from "../../../../apis/listings/sellers";
import {
  IAPIResponse,
  IPropertyFeatures,
} from "../../../common/utils/interfaces";
import Loading from "../../../common/components/Loading";
import { getLGAsList, getStatesList } from "../../../../apis/locations";
import { errorToastify, successToastify } from "../../../common/utils/Toasty";
import SuccessModal from "../../../common/components/SuccessModal";

const documentsTypes: { title: string; key: string }[] = [
  {
    title: "Certificate of Ownership",
    key: "certificate_of_ownership",
  },
  {
    title: "Building Permit",
    key: "building_permit",
  },
  {
    title: "Proof of Property Insurance",
    key: "proof_of_property_insurance",
  },
  {
    title: "Inspection Certificate",
    key: "inspection_certificates",
  },
];

const ListProperty: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const uploadImagesRef: any = React.useRef();
  const uploadVideosRef: any = React.useRef();

  const steps = 3;
  const [step, setStep] = useState(1);
  const [listingId, setListingId] = useState("");
  const [loadedFeatures, setLoadedFeatures] = useState<any>({});
  const [lgas, setLgas] = useState<{ id: number; name: string }[]>([]);
  const [states, setStates] = useState<
    { id: number; name: string; code: string }[]
  >([]);
  const [isLoadingLgas, setIsLoadingLgas] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState<
    { name: string; value: string }[]
  >([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [videoFiles, setVideoFiles] = useState<File[]>([]);
  const [docFiles, setDocFiles] = useState<any>({});
  const [featuresCheckboxData, setFeaturesCheckboxData] = useState<any[]>([]);
  const [inputData, setInputData] = useState<any>({});
  const [updateData, setUpdateData] = useState<any>({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (location.state) {
      const listingId = location.state.listingId;
      const listingUrl = location.state.listingUrl;
      if (listingId) {
        setListingId(listingId);
        loadPropertyDetails({ listingId });
      } else if (listingUrl) {
        setListingId(listingUrl);
        loadPropertyDetails({ listingUrl });
      }
    }
  }, [location]);

  useEffect(() => {
    const initPage = async () => {
      loadFeatures();
      // loadLgas();
      loadStates();
      loadPropertyTypes();
    };

    initPage();
  }, []);

  /* useEffect(() => {
    let realStateValue: number | undefined;
    const currentData = { ...inputData };

    if (
      currentData?.state &&
      !states?.some((state) => state.id === currentData?.state)
    ) {
      console.log('=====> figure > ', )
      //  this updates the "name" returned state to "id"
      realStateValue = states?.find(
        (state) => state.name === currentData?.state
      )?.id;
      currentData.state = realStateValue || currentData?.state;
    }

    if (
      states?.some((state) => state.id === inputData?.state || realStateValue)
    ) {
      loadLgas(realStateValue || inputData?.state);
      // currentData.lga = "";
    }

    setInputData(currentData);
  }, [inputData?.state, states]);

  useEffect(() => {
    const currentData = { ...inputData };
    //  this useEffect updates the "name" returned lga to "id"
    if (currentData?.lga && !lgas?.some((lga) => lga.id === currentData?.lga)) {
      currentData.lga =
        lgas?.find((lga) => lga.name === currentData?.lga)?.id ||
        currentData?.lga;
      setInputData(currentData);
    }
  }, [inputData?.lga, lgas]); */

  useEffect(() => {
    if (inputData?.state) {
      loadLgas(inputData?.state);
    }
  }, [inputData?.state]);

  const loadFeatures = async () => {
    setIsBusy(true);
    const result = await getFeaturesList();
    setIsBusy(false);

    if (result?.status) {
      const data: IPropertyFeatures[] = result.data;
      const categorized: any = categorizeFeatures(data);

      setLoadedFeatures(categorized);
    }
  };
  const loadLgas = async (stateValue: number) => {
    setIsBusy(true);
    setIsLoadingLgas(true);
    const result = await getLGAsList(stateValue);
    setIsBusy(false);
    setIsLoadingLgas(false);

    if (result?.status) {
      const data = result.data;
      setLgas(data);
    }
  };
  const loadStates = async () => {
    setIsBusy(true);
    const result = await getStatesList();
    setIsBusy(false);

    if (result?.status) {
      const data = result.data;
      setStates(data);
    }
  };
  const loadPropertyTypes = async () => {
    setIsBusy(true);
    const result = await getPropertyTypesList();
    setIsBusy(false);

    if (result?.status) {
      const data = result.data;
      setPropertyTypes(data);
    }
  };

  const loadPropertyDetails = async ({
    listingId,
    listingUrl,
  }: {
    listingId?: string;
    listingUrl?: string;
  }) => {
    setIsBusy(true);
    const response: IAPIResponse | undefined = await getMyListingDetails({
      id: listingId,
      url: listingUrl,
    });
    setIsBusy(false);

    if (response?.status) {
      setUpdateData(response.data);
    }
  };

  const updateFormData = (evt: ChangeEvent<HTMLInputElement> | any) => {
    const newFormData = { ...inputData, [evt.target.name]: evt.target.value };
    setInputData(newFormData);
  };

  const updateCheckboxes = (evt: ChangeEvent<HTMLInputElement>) => {
    const isChecked = evt.target.checked;
    const checkValue = Number(evt.target.value);

    let checkboxData = [...featuresCheckboxData];
    if (isChecked) {
      checkboxData.push(checkValue);
    } else {
      checkboxData = checkboxData.filter((item: number) => item !== checkValue);
    }

    setFeaturesCheckboxData(checkboxData);
    updateFormData({ target: { name: "features", value: checkboxData } });
  };

  const submitAllData = async () => {
    const finalData = convertInputDataToFormData(
      inputData,
      imageFiles,
      videoFiles,
      docFiles
    );

    setIsBusy(true);
    let response: IAPIResponse | undefined;

    if (listingId) {
      response = await updateListing(listingId, finalData);
    } else {
      response = await postNewListing(finalData);
    }

    if (response?.status) {
      setShowSuccessModal(true);

      successToastify("Listing added successfully");
      setImageFiles([]);
      setVideoFiles([]);
      setDocFiles({});
      setFeaturesCheckboxData([]);
      setInputData({});
      setUpdateData({});

      setTimeout(() => {
        setShowSuccessModal(false);
        navigate("/seller/dashboard");
      }, 2000);
    } else {
      errorToastify("An error occurred");
      setIsBusy(false);
    }
  };

  const handleFileImageChange = (event: any) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setImageFiles((prevFiles: any) => [...prevFiles, ...selectedFiles]);
    }
  };

  const handleFileVideoChange = (event: any) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setVideoFiles((prevFiles: any) => [...prevFiles, ...selectedFiles]);
    }
  };

  const updateDocuments = (fileId: string, uploadedFiles: File[]) => {
    const currentDocFiles = { ...docFiles, [fileId]: uploadedFiles };
    setDocFiles(currentDocFiles);
  };

  const deleteItem = (type: "image" | "video", index: number) => {
    let currentFiles;

    if (type === "image") {
      currentFiles = [...imageFiles];
      currentFiles.splice(index, 1);
      setImageFiles(currentFiles);
    } else {
      currentFiles = [...videoFiles];
      currentFiles.splice(index, 1);
      setVideoFiles(currentFiles);
    }
  };

  return (
    <SellerDashboardLayout noPadding>
      {!listingId || updateData.name ? (
        <>
          <div className="page-central">
            <div className="page-title">
              <ArrowBackIos
                style={{ fontSize: 28, cursor: "pointer" }}
                onClick={() => {
                  if (step <= 1) {
                    navigate("/seller/dashboard");
                  } else {
                    setStep((currentStep) => currentStep - 1);
                  }
                }}
              />
              <Typography variant="h4" style={{ fontSize: 18 }}>
                {listingId ? "Edit" : "List"} Property
              </Typography>
              <div className="_avatar">
                <Person style={{ fontSize: 65 }} />
              </div>
            </div>
            {step === 1 && (
              <div>
                <div className="page-header">
                  <Typography variant="h4">
                    Please fill property details appropriately in the form below
                  </Typography>
                </div>
                <div className="page-body">
                  <div className="form-form">
                    <div className="form-row">
                      <div className="form-input">
                        <Typography>Property type *</Typography>
                        <Select
                          name="property_type"
                          defaultValue={updateData?.property_type || "select"}
                          onChange={updateFormData}
                          fullWidth
                        >
                          <MenuItem value="select">Select one</MenuItem>
                          {propertyTypes?.map((propertyType) => (
                            <MenuItem
                              key={propertyType.value}
                              value={propertyType.value}
                            >
                              {propertyType.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className="form-input">
                        <Typography>Property name *</Typography>
                        <TextField
                          name="name"
                          defaultValue={updateData?.name || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Property name"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <Typography>Address *</Typography>
                        <TextField
                          name="street_name"
                          defaultValue={updateData?.street_name || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Street name *"
                        />
                      </div>
                      <div className="form-input">
                        <TextField
                          name="address"
                          defaultValue={updateData?.address || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Home number *"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <Select
                          name="state"
                          defaultValue={updateData?.state || "select"}
                          onChange={updateFormData}
                          fullWidth
                        >
                          <MenuItem value="select">Select State *</MenuItem>
                          {states?.map((state) => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className="form-input">
                        <Select
                          name="lga"
                          defaultValue={updateData?.lga || "select"}
                          onChange={updateFormData}
                          fullWidth
                          disabled={!inputData?.state || isLoadingLgas}
                        >
                          <MenuItem value="select">
                            Select LGA *
                            {isLoadingLgas
                              ? " (loading...)"
                              : inputData?.state
                              ? ""
                              : " (First, select State)"}
                          </MenuItem>
                          {lgas?.map((lga) => (
                            <MenuItem key={lga.id} value={lga.id}>
                              {lga.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <Typography>Geo-coordinates</Typography>
                        <TextField
                          name="latitude"
                          defaultValue={updateData?.latitude || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Latitude"
                        />
                      </div>
                      <div className="form-input">
                        {/* <Typography className="_not-visible">
                  Geo-coordinates
                </Typography> */}
                        <TextField
                          name="longitude"
                          defaultValue={updateData?.longitude || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Longitude"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <Typography>Price *</Typography>
                        <TextField
                          name="price"
                          defaultValue={updateData?.price || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Price"
                          type="number"
                        />
                      </div>
                      <div className="form-input">
                        <Typography>Currency *</Typography>
                        <Select
                          name="default_currency"
                          defaultValue={
                            updateData?.default_currency || "select"
                          }
                          onChange={updateFormData}
                          fullWidth
                        >
                          <MenuItem value="select">Select one</MenuItem>
                          <MenuItem value="NGN">NGN</MenuItem>
                          <MenuItem value="USD">USD</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <Typography>Square Footage *</Typography>
                        <TextField
                          name="square_footage"
                          defaultValue={updateData?.square_footage || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Square Footage"
                          type="number"
                        />
                      </div>
                      <div className="form-input">
                        <Typography>Price per sqft *</Typography>
                        <TextField
                          name="price_per_square_meter"
                          defaultValue={
                            updateData?.price_per_square_meter || ""
                          }
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Price per sqft"
                          type="number"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <Typography>Bedroom *</Typography>
                        <Select
                          name="bedrooms"
                          defaultValue={updateData?.bedrooms || "select"}
                          onChange={updateFormData}
                          fullWidth
                        >
                          <MenuItem value="select">Select one</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                        </Select>
                      </div>
                      <div className="form-input">
                        <Typography>Bathroom *</Typography>
                        <Select
                          name="bathrooms"
                          defaultValue={updateData?.bathrooms || "select"}
                          onChange={updateFormData}
                          fullWidth
                        >
                          <MenuItem value="select">Select one</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <Typography>Year built *</Typography>
                        <TextField
                          name="year_built"
                          defaultValue={updateData?.year_built || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Year built"
                          type="number"
                        />
                      </div>
                      <div className="form-input">
                        <Typography>Days on market for *</Typography>
                        <TextField
                          name="days_on_market_for"
                          defaultValue={updateData?.days_on_market_for || ""}
                          onChange={updateFormData}
                          fullWidth
                          placeholder="Days on market for"
                          type="number"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-input">
                        <Typography>Description *</Typography>
                        <TextField
                          name="description"
                          defaultValue={updateData?.description || ""}
                          onChange={updateFormData}
                          fullWidth
                          multiline
                          rows={10}
                          placeholder="Type here..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 2 && (
              <div>
                <div>
                  <div className="page-header">
                    <Typography variant="h4">
                      Select all features that apply
                    </Typography>
                  </div>
                  <div className="page-body _features">
                    {Object.keys(loadedFeatures).map(
                      (featureGroup: string, outdex: number) => (
                        <div key={outdex} className="feature-group-box">
                          <Typography variant="h5">{featureGroup}</Typography>
                          <div className="feature-group">
                            {loadedFeatures[featureGroup].map(
                              (feature: IPropertyFeatures, index: number) => (
                                <div className="feature-item" key={index}>
                                  {/* <CheckBox  /> */}
                                  <Checkbox
                                    name="features"
                                    id={feature.id.toString()}
                                    value={feature.id}
                                    onChange={updateCheckboxes}
                                  />
                                  <label htmlFor={feature.id.toString()}>
                                    <Typography>{feature.name}</Typography>
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div>
                <div>
                  <div className="page-header">
                    <Typography variant="h4">
                      Upload all the necessary files and documents
                    </Typography>
                  </div>
                  <div className="page-body">
                    <div className="list-group-box">
                      <Typography variant="h5">Property images</Typography>
                      <div className="list-group">
                        {new Array(Math.max(imageFiles.length, 3))
                          .fill(null)
                          .map((item: null, index: number) => (
                            <UploadedItem
                              key={`${index}-${imageFiles.length}-image`}
                              files={imageFiles}
                              type="image"
                              index={index}
                              deleteItem={() => deleteItem("image", index)}
                            />
                          ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <input
                          ref={uploadImagesRef}
                          type="file"
                          hidden
                          id="images"
                          onChange={handleFileImageChange}
                          accept=".png,.jpeg,.jpg,.bmp"
                          multiple
                        />

                        <Button
                          variant="contained"
                          onClick={() => uploadImagesRef.current.click()}
                        >
                          <Add style={{ fontSize: 28, marginRight: 5 }} />
                          Upload images
                        </Button>
                      </div>
                    </div>
                    <div className="list-group-box">
                      <Typography variant="h5">Virtual tour videos</Typography>
                      <div className="list-group">
                        {new Array(Math.max(videoFiles.length, 3))
                          .fill(null)
                          .map((item: null, index: number) => (
                            <UploadedItem
                              key={`${index}-${videoFiles.length}-video`}
                              files={videoFiles}
                              type="video"
                              index={index}
                              deleteItem={() => deleteItem("video", index)}
                            />
                          ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <input
                          ref={uploadVideosRef}
                          type="file"
                          hidden
                          id="videos"
                          onChange={handleFileVideoChange}
                          accept=".mp4,.mpeg,.mov,.avi,.wmv"
                          multiple
                        />
                        <Button
                          variant="contained"
                          onClick={() => uploadVideosRef.current.click()}
                        >
                          <Add style={{ fontSize: 28, marginRight: 5 }} />{" "}
                          Upload videos
                        </Button>
                      </div>
                    </div>
                    {documentsTypes.map((docType) => (
                      <div className="list-group-box" key={docType.key}>
                        <Typography variant="h5">{docType.title}</Typography>
                        <div className="list-group">
                          <CustomUpload
                            id={docType.key}
                            name={docType.title}
                            updateFiles={updateDocuments}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="dasboard-base">
            <Button
              variant="contained"
              disabled={isBusy}
              onClick={() => {
                if (step >= steps) {
                  submitAllData();
                } else {
                  setStep((currentStep) => currentStep + 1);
                  window.scrollTo(0, 0);
                }
              }}
              style={{ width: 300 }}
            >
              {`${step >= steps ? "Submit" : "Continue"}`}
              <Loading show={isBusy} />
            </Button>
          </div>

          <SuccessModal
            open={showSuccessModal}
            handleClose={() => setShowSuccessModal(false)}
            title="Listing submitted"
          />
        </>
      ) : (
        <></>
      )}
    </SellerDashboardLayout>
  );
};

export default ListProperty;
