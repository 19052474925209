import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface Props extends CustomModalProps {
  uploadDocument: () => Promise<any>
}
const DocumentVerification: React.FC<Props> = ({
  open,
  handleClose,
  submitAction,
  uploadDocument
}) => {
  const [documentSent, setDocumentSent] = useState(false);
  const submitDocument = async () => {
    // @ts-ignore
    const submitRequest = await uploadDocument()
    if(submitRequest) setDocumentSent(true)
    else handleClose()
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container text-center">
        {!documentSent && (
          <div className="my-10">
            <h2 className="modal__title">
              We'll verify your documents within 7 days
            </h2>
            <span className="modal__subtitle">
              You'll receive a confirmation once approved
            </span>
            <button
              onClick={submitDocument}
              className="modal__action"
            >
              SUBMIT FOR VERIFICATION
            </button>
          </div>
        )}
        {documentSent && (
          <div className="flex flex-col items-center">
            <h3 className="modal__title">Thank You!</h3>
            <p className="modal__subtitle mt-3  mb-[25px]">
              Your account is under review. You’ll be notified once your
              verification is complete and you can start listing properties
            </p>
            <img src="/assets/images/success-icon.svg" alt="" />
            <Link to="/sign-in">
              <button className="modal__action">RETURN TO LOGIN</button>
            </Link>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DocumentVerification;
