import { BACKEND_URL } from "../views/common/utils/constants";
import { IAPIResponse } from "../views/common/utils/interfaces";
import { errorToastify } from "../views/common/utils/Toasty";
import { CustomAxios } from "./customAxios";

export const getLGAsList = async (stateId: number) => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/locations/lgas?state_id=${stateId}`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const getStatesList = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/locations/states`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};
