import { SpeedDialIcon } from "@mui/material";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

const actions = [
  { icon: <img alt="phone" src="/assets/images/phone-white.svg" />, name: "Phone" },
  { icon: <img alt="message" src="/assets/images/message-white.svg" />, name: "Message" },
  { icon: <img alt="mail" src="/assets/images/mail-white.svg" />, name: "Mail" },
];

const CustomSpeedDial = () => {
  return (
    <Box
      sx={{
        height: 320,
        transform: "translateZ(0px)",
        flexGrow: 1,
        zIndex: 100,
      }}
    >
      <SpeedDial
        ariaLabel="Navigation"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon icon={<img src="/assets/images/chat.svg" alt="" />} openIcon={<img src="/assets/images/chat-filled.svg" alt="Chat" />}/>}
        FabProps={{
          sx: { backgroundColor: "white" },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            sx={{ backgroundColor: 'green' }}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default CustomSpeedDial;
