import React from "react";
import { Route } from "react-router-dom";
import UnprotectedRoute from "./views/common/global/UnprotectedRoute";
import ProtectedRoute from "./views/common/global/ProtectedRoute";

import {
  SellerSignIn as SellerSignInView,
  SellerSignUp as SellerSignUpView,
  SellerDashboard as SellerDashboardView,
  SellerListProperty as SellerListPropertyView,
  SellerListings as SellerListingsView,
  SellerTransactions as SellerTransactionsView,
} from "./views";
import { USER_TYPES } from "./views/common/utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";

const SellerRoutes = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.user);

  return (
    <>
      <Route
        path={`/seller/signin`}
        element={
          <UnprotectedRoute>
            <SellerSignInView />
          </UnprotectedRoute>
        }
      />
      <Route
        path={`/seller/signup`}
        element={
          <UnprotectedRoute>
            <SellerSignUpView />
          </UnprotectedRoute>
        }
      />
      <Route
        path={`/seller`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerDashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/dashboard`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerDashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/manage-account`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerDashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/transactions`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerTransactionsView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/list-property`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerListPropertyView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/listings`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerListingsView />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default SellerRoutes;
