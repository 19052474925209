import { useState } from "react";
import {
  IconMingcuteHeartLineOff,
  IconMingcuteHeartLineOn,
} from "./LayoutIcons";

const LikeListing = ({
  isLiked,
  doToggle,
}: {
  isLiked: boolean;
  doToggle?: () => void;
}) => {
  const [currentIsLiked, setCurrentIsLiked] = useState(isLiked);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        setCurrentIsLiked(!currentIsLiked);
        doToggle && doToggle();
      }}
      style={{ cursor: "pointer", marginLeft: "auto" }}
    >
      {currentIsLiked ? (
        <IconMingcuteHeartLineOn />
      ) : (
        <IconMingcuteHeartLineOff />
      )}
    </div>
  );
};

export default LikeListing;
