import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  loading: boolean;
  user: Record<string, any> | null; // Use Record for generic user object, can be typed more specifically if needed
  error: string | null;
  isAuthenticated: boolean;
}

const initialState: UserState = {
  loading: false,
  user: null,
  error: null,
  isAuthenticated: false,
};

export const loginUser = createAsyncThunk("/user/login", () => {});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Action to start the login process
    loginStart(state) {
      state.loading = true;
      state.error = null;
    },

    loginSuccess(state, action: PayloadAction<Record<string, any>>) {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    },

    loginFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    logout(state) {
      state.user = {};
      state.loading = false;
      state.error = null;
      state.isAuthenticated = false;
    },

    signUpStart(state) {
      state.loading = true;
      state.error = null;
    },

    signUpSuccess(state, action: PayloadAction<Record<string, any>>) {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    },
    signUpFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  signUpFailure,
  signUpStart,
  signUpSuccess,
} = userSlice.actions;
export default userSlice.reducer;
