import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import BuyerDashboardLayout from "../../components/DashboardLayout";
import MyPropertiesTable from "./components/MyPropertiesTable";

const MyProperties: React.FC = () => {
  const navigate = useNavigate();
  const [detailData, setDetailData] = useState<any>(null);
  const [fullDetailData, setFullDetailData] = useState<any>(null);
  const [filterState, setFilterState] = useState<any>({
    approved: false,
    pending: false,
    rejected: false,
  });

  useEffect(() => {
    /* const fetchData = async () => {
      const result: IAPIResponse | undefined = await getMyListingDetails(
        detailData
      );

      if (result) {
        const { data, count } = result;
        setFullDetailData(data);
      }
    }; */

    if (detailData) {
      // fetchData();
    }
  }, [detailData]);

  const onFilter = (evt: ChangeEvent<HTMLInputElement>, state: string) => {
    setFilterState({ ...filterState, [state]: evt.target.checked });
  };

  return (
    <BuyerDashboardLayout>
      <>
        <div className="page-central">
          <div>
            <MyPropertiesTable
              setDetailData={(value) => setDetailData(value)}
              filter={{ filter: filterState }}
            />
          </div>
        </div>
        {/* <CustomModal
          showModal={!!fullDetailData}
          onCloseModal={() => setFullDetailData(null)}
        >
          <PropertyListingApplication
            psuedoData={detailData}
            fullDetailData={fullDetailData}
            closeModal={() => setFullDetailData(null)}
          />
        </CustomModal> */}

        <div
          className="floating-button"
          onClick={() => navigate("/seller/list-property")}
        >
          <Add />
        </div>
      </>
    </BuyerDashboardLayout>
  );
};

export default MyProperties;
