import { useEffect, useRef, useState } from "react";
import sortData from "../../../../../data/sortData.json";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { getSavedListings } from "../../../../../../apis/listings/buyer";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SingleListing from "../../../../../common/components/SingleListing";
import { USER_TYPES } from "../../../../../common/utils/constants";
import { IBuyerListings } from "../../../../../common/utils/interfaces";
import BuyerDashboardLayout from "../../components/DashboardLayout";

const listt: IBuyerListings[] = [
  {
    url: "http://localhost:8000/api/listings/7e1dbe08-8e0d-4693-bfc6-b8a4215b91c9/",
    name: "Villa De Casa 1",
    description: "A very long description",
    bedrooms: 4,
    bathrooms: 4,
    rating: 0,
    features: [],
    address: "address somewhere",
    street_name: "Street somewhere",
    price: "200000.00",
    default_currency: "USD",
    pics: [
      {
        file: "http://localhost:8000/media/listings/c9508352-8c7b-4e37-8b77-07d8511a10b6/image/-5204492255007501378..jpg",
        file_type: "image",
      },
    ],
    latitude: null,
    longitude: null,
    saved_by_user: true,
    toggle_save_url:
      "http://localhost:8000/api/listings/7e1dbe08-8e0d-4693-bfc6-b8a4215b91c9/toggle-save",
  },
  {
    url: "http://localhost:8000/api/listings/c9508352-8c7b-4e37-8b77-07d8511a10b6/",
    name: "Villa De Casa",
    description: "A very long description",
    bedrooms: 4,
    bathrooms: 4,
    rating: 0,
    features: [
      {
        id: 1,
        name: "Walk-In Closet",
        category: "interior_features",
      },
      {
        id: 2,
        name: "Fireplace",
        category: "interior_features",
      },
      {
        id: 3,
        name: "Balcony/Terrace",
        category: "exterior_features",
      },
      {
        id: 4,
        name: "Courtyard",
        category: "exterior_features",
      },
      {
        id: 5,
        name: "Dishwasher",
        category: "kitchen_features",
      },
      {
        id: 6,
        name: "Pantry",
        category: "kitchen_features",
      },
      {
        id: 7,
        name: "Ocean View",
        category: "views_and_location",
      },
      {
        id: 8,
        name: "City View",
        category: "views_and_location",
      },
      {
        id: 9,
        name: "Park View",
        category: "views_and_location",
      },
    ],
    address: "address somewhere",
    street_name: "Street somewhere",
    price: "200000.00",
    default_currency: "USD",
    pics: [
      {
        file: "http://localhost:8000/media/listings/c9508352-8c7b-4e37-8b77-07d8511a10b6/image/-5204492255007501378..jpg",
        file_type: "image",
      },
    ],
    latitude: null,
    longitude: null,
    saved_by_user: false,
    toggle_save_url:
      "http://localhost:8000/api/listings/c9508352-8c7b-4e37-8b77-07d8511a10b6/toggle-save",
  },
  {
    url: "http://localhost:8000/api/listings/928621d3-2c1b-4176-991a-332d7b636f57/",
    name: "Home Away from Home 3",
    description: "A very long description",
    bedrooms: 4,
    bathrooms: 4,
    rating: 0,
    features: [
      {
        id: 1,
        name: "Walk-In Closet",
        category: "interior_features",
      },
      {
        id: 2,
        name: "Fireplace",
        category: "interior_features",
      },
      {
        id: 3,
        name: "Balcony/Terrace",
        category: "exterior_features",
      },
      {
        id: 4,
        name: "Courtyard",
        category: "exterior_features",
      },
      {
        id: 5,
        name: "Dishwasher",
        category: "kitchen_features",
      },
      {
        id: 6,
        name: "Pantry",
        category: "kitchen_features",
      },
    ],
    address: "Somewhere",
    street_name: "Nowhere",
    price: "200000.00",
    default_currency: "USD",
    pics: [
      {
        file: "http://localhost:8000/media/listings/c9508352-8c7b-4e37-8b77-07d8511a10b6/image/-5204492255007501378..jpg",
        file_type: "image",
      },
    ],
    latitude: null,
    longitude: null,
    saved_by_user: false,
    toggle_save_url:
      "http://localhost:8000/api/listings/928621d3-2c1b-4176-991a-332d7b636f57/toggle-save",
  },
];

const SavedItems = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const listings = useSelector((state: RootState) => state.listings);

  const { sortOptions } = sortData;
  const [showFilter, setShowFilter] = useState(false);
  const [showStatesModal, setShowStatesModal] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const sortRef = useRef<HTMLDivElement | null>(null);
  const toggleShowSort = () => setShowSort(!showSort);

  console.log("fhdldf", listings);
  useEffect(() => {
    dispatch(getSavedListings());
  }, [dispatch]);

  const hideModals = (event: React.MouseEvent<HTMLDivElement>) => {
    if (showStatesModal) return;
    if (
      showFilter &&
      filterRef.current &&
      !filterRef.current.contains(event?.target as Node)
    ) {
      setShowFilter(false);
    }
    if (
      showSort &&
      sortRef.current &&
      !sortRef.current.contains(event?.target as Node)
    ) {
      setShowSort(false);
    }
  };

  return (
    <BuyerDashboardLayout>
      <div className="buyer-dashboard-core">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <span className="_page-title">Saved Items</span>
          <Button
            variant="text"
            style={{
              padding: 9,
              minWidth: "unset",
            }}
          >
            See all
          </Button>
        </div>
        {/* <div className="grid sm:grid-cols-2 gap-10"> */}
        <div className="dashboard-layout">
          <div className="listing-group">
            {listings.loading ? (
              <span className="body-description no-records">Loading...</span>
            ) : listings.listings?.length ? (
              (listings.listings as any[]).map((listing) => (
                //   listt.map((listing) => (
                //   <FeaturedProperty key={listing.name} listing={listing} />
                <SingleListing
                  section={USER_TYPES.BUYER}
                  listingData={listing}
                />
              ))
            ) : (
              <span className="body-description no-records">
                There are no listings yet
              </span>
            )}
          </div>
        </div>
      </div>
    </BuyerDashboardLayout>
  );
};

export default SavedItems;
