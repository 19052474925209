import { BACKEND_URL } from "./constants";

const baseUrl = BACKEND_URL;
const apiRoutes = {
  accounts: {
    register: `${baseUrl}/accounts/register`,
    sellerRegister: `${baseUrl}/accounts/register?ut=seller`,
    login: `${baseUrl}/accounts/login`,
    verifyRegistrationOtp: `${baseUrl}/accounts/verify-otp`,
    verifyAuthOtp: `${baseUrl}/accounts/verify-auth-otp`,
    uploadFiles: `${baseUrl}/accounts/profile/certs/add`,
    logout: `${baseUrl}/accounts/logout`,
    addCompanyInfo: `${baseUrl}/accounts/profile/company-info/add`,
    forgotPassword: `${baseUrl}/accounts/forgot-password`,
    setPassword: `${baseUrl}/accounts/set-password`,
    resendOtp: `${baseUrl}/accounts/resend-otp?type=1`,
    refreshAccessToken: `${baseUrl}/accounts/refresh`,
  },
  locations: {
    states: `${baseUrl}/locations/states`,
    lgas: `${baseUrl}/locations/lgas`,
    towns: `${baseUrl}/locations/towns`,
  },
  buyerListing: {
    view: `${baseUrl}/listings/open`,
  },
};

export default apiRoutes;
