import React from "react";
import { LoaderFunctionArgs, Navigate, Route } from "react-router-dom";
import UnprotectedRoute from "./views/common/global/UnprotectedRoute";
import ProtectedRoute from "./views/common/global/ProtectedRoute";

import {
  BuyerSignIn as BuyerSignInView,
  BuyerSignUp as BuyerSignUpView,
  BuyerHome as BuyerHomeView,
  PropertyDetails as PropertyDetailsView,
  PropertyPurchase as PropertyPurchaseView,
  PurchaseCheckout as PurchaseCheckoutView,
  PropertySearch as PropertySearchView,
  BuyerDashboard as BuyerDashboardView,
  BuyerTransactions as BuyerTransactionsView,
  BuyerSavedItems as BuyerSavedItemsView,
  BuyerProperties as BuyerPropertiesView,
  About as AboutView,
  Faq as FaqView
} from "./views";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import axios from "axios";
import apiRoutes from "./views/common/utils/apiRoutes";
import { ListingInterface } from "./types";
import { USER_TYPES } from "./views/common/utils/constants";

const BuyerRoutes = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.user);

  const listingLoader = async ({
    params,
  }: LoaderFunctionArgs): Promise<ListingInterface> => {
    const listingId = params.listingId;
    const response = await axios.get(
      `${apiRoutes.buyerListing.view}/${listingId}`
    );
    const data = response.data.data;
    return data;
  };
  return (
    <>
      <Route
        path="/"
        element={
          <UnprotectedRoute>
            <BuyerHomeView />
          </UnprotectedRoute>
        }
      />
      <Route
        path={`/sign-in`}
        element={
          <UnprotectedRoute>
            <>
              <BuyerSignInView />
            </>
          </UnprotectedRoute>
        }
      />
      <Route
        path={`/sign-up`}
        element={
          <UnprotectedRoute>
            <>
              <BuyerSignUpView />
            </>
          </UnprotectedRoute>
        }
      />
      <Route
        path="/listings/:listingId"
        element={
          <UnprotectedRoute>
            <PropertyDetailsView />
          </UnprotectedRoute>
        }
        loader={listingLoader}
      />
      <Route
        path="/property-search/:searchText"
        element={
          <UnprotectedRoute>
            <PropertySearchView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/property-search"
        element={
          <UnprotectedRoute>
            <PropertySearchView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/about"
        element={
          <UnprotectedRoute>
            <AboutView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/faqs"
        element={
          <UnprotectedRoute>
            <FaqView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/buy-property"
        element={
          <UnprotectedRoute>
            <PropertyPurchaseView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/property-checkout"
        element={
          <UnprotectedRoute>
            <PurchaseCheckoutView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerDashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/activities"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerDashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/transactions"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerTransactionsView />
          </ProtectedRoute>
        }
      />{" "}
      <Route
        path="/dashboard/my-properties"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerPropertiesView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/saved-items"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerSavedItemsView />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default BuyerRoutes;
