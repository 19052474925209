import axios from "axios";
import { AppDispatch } from "../../store/store";
import apiRoutes from "../../views/common/utils/apiRoutes";
import {
  fetchListings,
  fetchListingsFailure,
  fetchListingsSuccess,
} from "../../store/slices/buyer/listingsSlice";
import {
  fetchListing,
  fetchListingFailure,
  fetchListingSuccess,
} from "../../store/slices/buyer/listingSlice";
import { CustomAxios } from "../customAxios";
import { IAPIResponse } from "../../views/common/utils/interfaces";
import { BACKEND_URL } from "../../views/common/utils/constants";
import { errorToastify } from "../../views/common/utils/Toasty";

export const getListings = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchListings());
    const response = await axios.get(apiRoutes.buyerListing.view);
    dispatch(fetchListingsSuccess(response.data.data));
  } catch (error: any) {
    dispatch(
      fetchListingsFailure(
        error.response?.data?.message || "Could not fetch listings"
      )
    );
  }
};

export const getListing = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchListing());
    const response = await axios.get(`${apiRoutes.buyerListing.view}/${id}`);
    dispatch(fetchListingSuccess(response.data.data));
  } catch (error: any) {
    dispatch(
      fetchListingFailure(
        error.response?.data?.message || "Could not fetch listing"
      )
    );
  }
};

export const getSavedListings = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchListing());
    const response: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings?saved_by_user=true`
    );

    if (!response?.data?.status) {
      throw new Error(response?.data?.message);
    }
    dispatch(fetchListingSuccess(response.data.data));
    // return response;
  } catch (error: any) {
    dispatch(
      fetchListingFailure(
        error.response?.data?.message || error || "Could not fetch listings"
      )
    );
  }
};

export const postToggleSaveListing = async ({
  id,
  url,
}: {
  id?: string;
  url?: string;
}) => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().post(
      `${BACKEND_URL}/listings/${id || url}/toggle-save`
    );
    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};
