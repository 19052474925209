import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../../types";

interface Props extends CustomModalProps {
  propertyName: string;
  location: string,
}
const PropertyVerification: React.FC<Props> = ({
  handleClose,
  open,
  submitAction,
  propertyName,
  location
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="payment-modal">
        <p className="payment-modal__title">
          Payment for Property Verification
        </p>
        <div className="payment-modal__price">
          <p className="dollar">$10</p>
          <p className="naira">(N17,000)</p>
        </div>
        <p className="payment-modal__field">Property Name:</p>
        <p className="payment-modal__value">{propertyName}, {location}</p>
        <div className="mt-[41px] mb-[34px]">
          <p className="payment-modal__field">Property Documents:</p>
          <div className="flex flex-wrap justify-center gap-x-8">
            <p className="payment-modal__value">*Title Document</p>
            <p className="payment-modal__value">*Building Permit</p>
            <p className="payment-modal__value">*Valuation Report</p>
            <p className="payment-modal__value">*Legal Search Report</p>
          </div>
        </div>
        <p className="payment-modal__info">
          You will be notified immediately the verification documents are
          available. <br /> <br /> Note that this payment is non-refundable
        </p>
        <button onClick={submitAction} className="filled-button w-full">
          Click Here To Pay
        </button>
      </div>
    </Modal>
  );
};

export default PropertyVerification;
