export type CurrencyType = "USD" | "GBP" | "CAD" | "NGN";

export const changeCurrency = (amount: number, newCurr: CurrencyType) => {
  amount = Number(amount)
  const rate = {
    USD: 1640,
    GBP: 2128,
    CAD: 1175,
    NGN: 1,
  };
  let newAmount = 1;
  if (newCurr === "NGN") {
    return new Intl.NumberFormat().format(amount);
  } else {
    if (newCurr === "USD") newAmount = amount / rate.USD;
    else if (newCurr === "GBP") newAmount = amount / rate.GBP;
    else if (newCurr === "CAD") newAmount = amount / rate.CAD;
    
    return new Intl.NumberFormat('en-Us', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(newAmount);
  }
};
