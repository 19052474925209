import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
const MyGoogleMap = () => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: 9.0667,
    lng: 7.4833,
  };
  const MAP_API_KEY = 'AIzaSyCxAxT-kcT7FANseSEYGzeSerAXiNSJND8'
  return (
    <LoadScript googleMapsApiKey={MAP_API_KEY}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MyGoogleMap;
