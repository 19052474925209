import axios, { AxiosInstance } from "axios";
import { logoutClearLocalStorage } from "../views/common/utils/helpers";
import { errorToastify } from "../views/common/utils/Toasty";
import { refreshAccessToken } from "./accounts/auth";
import { BACKEND_URL } from "../views/common/utils/constants";

//getting the token from the local storage
export const CustomAxios = (args?: any) => {
  const overrideNotification = args?.overrideNotification || false;

  const accessToken =
    localStorage.getItem("access_token") === null
      ? false
      : localStorage.getItem("access_token");
  // created a custom axios that will used for mostly all request
  try {
    const axiosInstance = axios.create({
      baseURL: BACKEND_URL,
      headers: {
        Authorization: `Bearer ${accessToken && accessToken}`,
        "Content-Type": "application/json",
      },
    });

    axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      async (error: any) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          //  fetch refresh token
          const { access_token } = await refreshAccessToken();

          if (access_token) {
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${access_token}`;
            return axiosInstance(originalRequest); // Retry the original request with the new access token.
          }

          logoutClearLocalStorage();

          return (window.location.href = `/sign-in?redirect=${
            window.location.pathname
          }${window.location.search || ""}`);
        } else if (error?.response?.status === 403) {
          !overrideNotification &&
            errorToastify(
              error?.response?.data?._meta?.error?.message || "NOT AUTHOURIZED"
            );

          /* logoutClearLocalStorage();

          return (window.location.href = `/sign-in?redirect=${
            window.location.pathname
          }${window.location.search || ""}`); */
        } else if (error?.response?.status === 500) {
          !overrideNotification &&
            errorToastify("Error occured. Kindly notify support.");
          // error.message = "Error occured. Kindly notify support.";
          error.response.data._meta.error.message =
            "Error occured. Kindly notify support.";
        } else if (error?.toString()?.toLowerCase()?.includes("network")) {
          errorToastify("Kindly check your connection and try again");
        }

        errorToastify("Kindly check your connection and try again");

        //  TODO: tech debt - throw error to calling function (could be conditional)
        /* if (error?.response) {
          return Promise.reject(error);
        } else {
          throw error;
        } */
        return error?.response ? Promise.reject(error) : error;
        // return Promise.reject(error);
      }
    );
    return axiosInstance;
  } catch (err) {
    console.log("error");
    return err as AxiosInstance;
  }
};
