import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import React, { useState } from "react";
import { Popover, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ListingInterface, ListingsInterface } from "../../../types";
import { extractId } from "../../common/utils/helpers";
import {
  changeCurrency,
  CurrencyType,
} from "../../common/utils/changeCurrency";

const ListingCard = ({ listing }: { listing: ListingsInterface }) => {
  const [currency, setCurrency] = useState<CurrencyType>("NGN");

  const [isFavorite, setIsFavorite] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const formatToNaira = (amount: number) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  const showCurrencyPop = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="property-card">
      <div className="h-[266px] relative overflow-hidden w-full rounded-t-[10px]">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          className="mySwiper"
          pagination={true}
          modules={[Pagination]}
        >
          <CustomSwiperButton />
          {listing.pics.map((img) => (
            <SwiperSlide key={img.file}>
              <img
                src={img.file}
                className="object-cover w-full h-full rounded-t-[10px]"
                alt={listing.name}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="absolute top-[10px] gap-x-2 left-[15px] right-[15px] flex items-center z-10">
          {/* <button className="bg-primary flex items-center space-x-3 rounded-[4px] py-[6px] px-[13px] text-white">
            <img className="" src="/assets/images/cart-white.svg" alt="" />
            <span>Add to cart</span>
          </button>
          <button className="bg-primary rounded-[4px] py-[6px] px-[13px] text-white">
            Compare
          </button> */}
          <div
            onClick={() => setIsFavorite(!isFavorite)}
            className="h-[29px] cursor-pointer w-[29px] ml-auto rounded-full flex items-center justify-center bg-white"
          >
            <img
              className="h-[14px] w-[16px]"
              src={`/assets/images/${
                isFavorite ? "filled-love.svg" : "outline-love.png"
              }`}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="shadow-lg relative py-5 h-fit px-[18.5px]">
        <div className="flex items-center h-fit">
          <div className="flex items-center space-x-[2px]">
            <img src="/assets/images/filled-star.svg" alt="" />
            <img src="/assets/images/filled-star.svg" alt="" />
            <img src="/assets/images/filled-star.svg" alt="" />
            <img src="/assets/images/filled-star.svg" alt="" />
            <img src="/assets/images/outline-star.svg" alt="" />
          </div>
          <div className="flex h-full space-x-1 pl-[10px] ml-[10px] border-l-2 items-center">
            <img src="/assets/images/verified.svg" alt="" />
            <span className="text-[#1570EF] text-[14px]">Verified</span>
          </div>
        </div>
        <Link to={`/listings/${extractId(listing.url)}`}>
          <h3 className="property-card__name">{listing.name}</h3>
        </Link>
        <p className="property-card__address">{listing.state}</p>
        <div className="flex space-x-2 mb-1 text-[14px] sm:text-[16px]">
          <p className="text-[13px] text-textBlack2 my-1 whitespace-nowrap overflow-hidden text-ellipsis">
            {listing?.features?.map((feature, index: number, features) => (
              <React.Fragment key={index}>
                {/* @ts-ignore */}
                {feature.name} {index < features.length - 1 && <span>| </span>}
              </React.Fragment>
            ))}
            {!listing?.features?.length && <div>--</div>}
          </p>
        </div>
        <div className="flex flex-wrap gap-x-2 gap-y-1">
          <span className="property-card__pill">Certified Developer</span>
          <span className="property-card__pill">Verified Title Deed</span>
          <span className="property-card__pill">Fraud Protection</span>
          <span className="property-card__pill">Certified Land Ownership</span>
          <span className="property-card__pill">No Legal disputes</span>
        </div>
        <div className="flex text-[16px] mt-5">
          <span className="pr-2 border-r-2 border-[#CECECE] mr-2">3 beds</span>
          <span>2.5 baths</span>
          <div className="flex relative overflow-visible space-x-[11px] z-[20] font-semibold items-center ml-auto">
            <span className="flex">
              <img src={`/assets/images/${currency}.svg`} alt="" />{" "}
              {changeCurrency(listing.price, currency)}
            </span>
            <div>
              <button onClick={showCurrencyPop}>
                <img
                  className="cursor-pointer"
                  src="/assets/images/chevron-down.svg"
                  alt=""
                />
              </button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="flex flex-col gap-y-3">
                  <button
                    onClick={() => setCurrency("USD")}
                    className="flex gap-x-3 px-4 py-[10px]"
                  >
                    <img src="/assets/images/dollar.svg" alt="" />
                    <span>USD</span>
                  </button>
                  <button
                    onClick={() => setCurrency("NGN")}
                    className="flex gap-x-3 px-4 py-[10px]"
                  >
                    <img src="/assets/images/naira.svg" alt="" />
                    <span>NGN</span>
                  </button>
                  <button
                    onClick={() => setCurrency("CAD")}
                    className="flex gap-x-3 px-4 py-[10px]"
                  >
                    <img src="/assets/images/canadian-dollar.svg" alt="" />
                    <span>CAD</span>
                  </button>
                  <button
                    onClick={() => setCurrency("GBP")}
                    className="flex gap-x-3 px-4 py-[10px]"
                  >
                    <img src="/assets/images/pounds.svg" alt="" />
                    <span>GBP</span>
                  </button>
                </div>
              </Popover>
            </div>
          </div>
        </div>
        <Link to={`/listings/${extractId(listing.url)}`}>
          <button className="filled-button !px-0 w-full mt-[17px]">
            VIEW DETAILS
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ListingCard;

const CustomSwiperButton = () => {
  const swiper = useSwiper();
  return (
    <div className="flex z-50 justify-between absolute w-full px-1 top-[48%]">
      <img
        onClick={() => swiper.slidePrev()}
        className="scale-x-[-1]"
        src="/assets/images/right-arrow.svg"
        alt=""
      />
      <img
        onClick={() => swiper.slideNext()}
        className=""
        src="/assets/images/right-arrow.svg"
        alt=""
      />
    </div>
  );
};
