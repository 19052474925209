import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import OTPInput from "react-otp-input";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resendUserOtp, verifyUserOtp } from "../../../apis/accounts/auth";
import { AppDispatch, RootState } from "../../../store/store";
import { useNavigate } from "react-router-dom";

interface Props extends CustomModalProps {
  email: string;
}
const LoginVerification: React.FC<Props> = ({ open, handleClose, email }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [resendOtpText, setResendOtpText] = useState("");
  const [otpSent, setOtpSent] = useState(true);
  const { error } = useSelector((state: RootState) => state.user);

  const verifyOtp = async () => {
    const user = await dispatch(verifyUserOtp(email, otp));
    if(user){
      if(user['user-types'][0].type === "buyer") navigate("/")
      else if(user['user-types'][0].type === "seller") navigate("/seller/dashboard")
      else navigate("/admin/dashboard")
    }

  };
  const resendOtp = async () => {
    const isSuccess = await dispatch(resendUserOtp(email));
    isSuccess && setResendOtpText("OTP Resent");
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container">
        <h2 className="modal__title">Sign-in Verification</h2>
        {!otpSent && (
          <>
            <span className="block mb-[30px] text-[18px] text-[#0D0D0D99]">
              Kindly select where your OTP should be sent to:-
            </span>
            <div className="rounded-[10px] flex border shadow-md border-[#36363680] h-[50px]">
              <div className="h-full flex items-center justify-center w-[50px] border-r border-[#36363680]">
                <img src="/assets/images/mail.svg" alt="" />
              </div>
              <input
                type="email"
                value={email}
                className="h-full flex-1 focus:outline-none pl-[17px] text-[#363636C2] font-[500] text-[14px] sm:text-[18px]"
              />
            </div>
            <button onClick={() => setOtpSent(true)} className="modal__action">
              PROCEED
            </button>
          </>
        )}
        {otpSent && (
          <>
            {resendOtpText && <span>{resendOtpText}</span>}
            {error && <span className="text-red-500">{error}</span>}
            <p className="subheadings mt-[5px] mb-[30px] !text-[16px] sm:!text-[18px]">
              Please verify your sign in with the OTP sent to {email}
            </p>
            <div className="flex items-center justify-between">
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                containerStyle={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
                renderInput={(props) => (
                  <input
                    {...props}
                    maxLength={1}
                    type="text"
                    className="border inline-block text-center w-[70px] border-[#E1E1E1] flex-1 h-[50px] rounded-[10px]"
                    style={{ boxShadow: "2px 2px 4px 0px #066D0E2E" }}
                  />
                )}
              />
            </div>
            <p
              onClick={resendOtp}
              className="underline mt-[5px] cursor-pointer"
            >
              Resend code
            </p>

            <button onClick={verifyOtp} className="modal__action">
              LOGIN
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default LoginVerification;
