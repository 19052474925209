import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountVerification from "../../modals/AccountVerification";
import { signUpUser } from "../../../../apis/accounts/auth";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { AppDispatch, RootState } from "../../../../store/store";

function SignUp() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [formInput, setFormInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password1: "",
    password2: "",
    phoneNumber: "",
    companyName: "",
    companyNumber: "",
  });

  const [displayModals, setDisplayModals] = useState({
    accountVerification: false,
  });

  const lowercaseRegex = /[a-z]/;
  const hasLowerCase = lowercaseRegex.test(formInput.password1);
  const uppercaseRegex = /[A-Z]/;
  const hasUpperCase = uppercaseRegex.test(formInput.password1);
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const hasSpecialCharacter = specialCharRegex.test(formInput.password1);
  const passwordMatch = formInput.password1 === formInput.password2;

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();

  const openModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: true }));
  const closeModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: false }));
  const closeAllModal = () =>
    setDisplayModals({
      accountVerification: false,
    });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInput((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const [errorMsg, setErrorMsg] = useState("");

  const signUp = async () => {
    if(!passwordMatch || !hasLowerCase || !hasUpperCase || !hasSpecialCharacter || formInput.password1.length < 12 ) return
    setErrorMsg("")
    if (
      !formInput.email ||
      !formInput.first_name ||
      !formInput.last_name ||
      !phoneNumber ||
      !formInput.password1 ||
      !formInput.password2
    ) {
      setErrorMsg("Please fill the required fields");
      return;
    }
    const isSuccess = await dispatch(
      signUpUser(
        formInput.email,
        formInput.first_name,
        formInput.last_name,
        phoneNumber || "",
        "NG",
        formInput.password1,
        formInput.password2
      )
    );
    isSuccess && openModal("accountVerification");
  };
  const routeToLogin = () => navigate("/sign-in");
  const { loading, error } = useSelector((state: RootState) => state.user);

  return (
    <div className="pt-10 relative">
      <img
        src="/assets/images/background.svg"
        className="absolute top-0 left-0 z-[-1] h-full w-full object-cover"
        alt=""
      />
      <div className="flex space-x-4 items-center justify-center">
        <img className="h-[40px]" src="/assets/images/logo.svg" alt="" />
        <div className="flex flex-col items-center italic uppercase text-[#084E14] font-[600] text-[16px]">
          <h4>Home</h4>
          <div className="flex space-x-1 items-center">
            <div className="w-[54px] h-[2px] bg-custom-gradient" />
            <span className="bg-custom-gradient text-[16px] bg-clip-text text-transparent">
              &
            </span>
            <div className="w-[54px] h-[2px] bg-custom-gradient" />
          </div>
          <h4>Abroad</h4>
        </div>
      </div>
      <h3 className="text-primary text-center font-[500] text-[16px]">
        Simplifying Property Purchases in Nigeria
      </h3>
      <section className="mt-[20px] bg-white px-[35px] pt-[10px] pb-5 shadow-md rounded-[20px] mx-auto max-w-[1000px]">
        <div>
          <h2 className="text-textBlack font-[600] text-[18px]">
            Account Creation
          </h2>
          <p className="text-textGrey text-[14px]">
            Fill all input fields correctly
          </p>
          <div className="grid sm:grid-cols-2 gap-[15px] mt-[26px]">
            <div>
              <label htmlFor="first_name" className="custom__label">
                Last Name*
              </label>
              <input
                type="text"
                onChange={handleInputChange}
                value={formInput.first_name}
                id="first_name"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="last_name" className="custom__label">
                Other Names*
              </label>
              <input
                value={formInput.last_name}
                onChange={handleInputChange}
                type="text"
                id="last_name"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="email" className="custom__label">
                Email*
              </label>
              <input
                value={formInput.email}
                onChange={handleInputChange}
                id="email"
                type="email"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="custom__label">
                Phone Number*
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                international
                value={phoneNumber}
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1] focus:outline-none"
                onChange={setPhoneNumber}
                defaultCountry="NG"
              />
            </div>
            <div>
              <label htmlFor="password1" className="custom__label">
                Password*
              </label>
              <input
                id="password1"
                onChange={handleInputChange}
                value={formInput.password1}
                type="password"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="password2" className="custom__label">
                Confirm Password*
              </label>
              <input
                id="password2"
                onChange={handleInputChange}
                value={formInput.password2}
                type="password"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="companyName" className="custom__label">
                Company Name (Optional)
              </label>
              <input
                id="companyName"
                onChange={handleInputChange}
                value={formInput.companyName}
                type="text"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="companyNumber" className="custom__label">
                Company Registration Number (Optional)
              </label>
              <input
                id="companyNumber"
                onChange={handleInputChange}
                value={formInput.companyNumber}
                type="text"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
          </div>
          {errorMsg && <span className="text-red-500">{errorMsg}</span>}
          {error && <span className="text-red-500">{error}</span>}
          <button
            onClick={signUp}
            className="uppercase block w-full mx-auto sm:max-w-[50%] bg-primary text-textWhite rounded-[10px] mt-[30px] mb-[5px] py-[10px] text-[14px] font-[600]"
          >
            Sign Up
          </button>
        </div>
        {formInput.password1 && (
          <div className="flex flex-col gap-y-1 text-red-500">
            <span className={`${hasLowerCase && "text-green-500"}`}>
              Password must contain small letter
            </span>
            <span
              className={`${
                formInput.password1.length >= 12 && "text-green-500"
              }`}
            >
              Password must contain not be less than 12 characters
            </span>
            <span className={`${hasUpperCase && "text-green-500"}`}>
              Password must contain capital letter
            </span>
            <span className={`${hasSpecialCharacter && "text-green-500"}`}>
              Password must contain special characters
            </span>
          </div>
        )}
        <p className="text-center text-[14px] text-black/50">
          Or Sign in using
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-x-5 gap-y-2 mt-2 mb-[10px]">
          <button className="flex w-full sm:w-fit justify-center sm:justify-start items-center space-x-7 rounded-[10px] py-[10px] pr-[25px] pl-3 border border-[#E1E1E1]">
            <img src="/assets/images/google.svg" alt="" />
            <span className="text-textBlack text-[14px] font-[400]">
              Login using Google
            </span>
          </button>
          <button className="flex w-full sm:w-fit justify-center sm:justify-start items-center space-x-7 rounded-[10px] py-[10px] pr-[25px] pl-3 border border-[#E1E1E1]">
            <img className="h-8" src="/assets/images/apple.svg" alt="" />
            <span className="text-textBlack text-[14px] mx-auto font-[400]">
              Login using Apple
            </span>
          </button>
        </div>
        <p className="text-center text-textBlack font-[500] text-[12px]">
          Already have an account?{" "}
          <span className="text-primary">
            <Link to="/sign-in">Sign In</Link>
          </span>
        </p>
      </section>
      <AccountVerification
        open={displayModals.accountVerification}
        handleClose={() => closeModal("accountVerification")}
        submitAction={routeToLogin}
        email={formInput.email}
      />
    </div>
  );
}

export default SignUp;
