import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../../types";

const PayWithCard: React.FC<CustomModalProps> = ({ handleClose, open, submitAction }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="payment-modal h-[80vh] flex flex-col">
        <p className="text-textBlack font-semibold">Pay With Card</p>
        <p className="my-auto text-primary text-4xl font-semibold">Coming Soon!</p>
        <button onClick={handleClose} className="filled-button w-full">Close</button>
      </div>
    </Modal>
  );
};

export default PayWithCard;
