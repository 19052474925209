import { Link } from "react-router-dom";
import UserDropdown from "../../../../modals/UserDropdown";
import MobileNav from "../../../../../Seller/components/MobileNav";
import SupportDropdown from "../../../../../Seller/modals/SupportDropdown";
import { useSelector } from "react-redux";

const DashboardNavBar = () => {
  // @ts-ignore

  const { isAuthenticated } = useSelector((state: RootState) => state.user);

  return (
    <nav
      className="flex h-[94px] items-center px-4 sm:px-[50px] text-textBlack font-medium text-[16px] card-shadow"
      style={{
        position: "fixed",
        minHeight: 94,
        width: "100%",
        zIndex: 100,
        backgroundColor: "#ffffff",
      }}
    >
      <Link to="/">
        <div
          className="flex items-center space-x-[7px]"
          style={{ minWidth: 135 }}
        >
          <img className="h-11" src="/assets/images/logo.svg" alt="" />
          <div className="flex flex-col items-center text-[15.28px] font-semibold italic text-primary">
            <p>HOME</p>
            <div className="flex items-center space-x-[3px]">
              <div className="w-[26px] bg-custom-gradient h-[2px]" />
              <span className="gradient-text text-[10px]">&</span>
              <div className="w-[26px] bg-custom-gradient h-[2px]" />
            </div>
            <p>ABROAD</p>
          </div>
        </div>
      </Link>
      <div className="hidden sm:contents">
        <ul className="nav-links">
          <Link to="/property-search">
            <li className="nav-link">Buy Now</li>
          </Link>
          <div className="flex px-[15px] flex-col items-center">
            <span className="text-[10px] text-red-500">Coming soon</span>
            <li className="">Mortgage</li>
          </div>
          <div className="flex px-[15px] flex-col items-center">
            <span className="text-[10px] text-red-500">Coming soon</span>
            <li className="">Installment</li>
          </div>
          <Link to="/faqs">
            <li className="nav-link">FAQs</li>
          </Link>
        </ul>
        <div className="ml-9 mr-5 hidden sm:block relative">
          <SupportDropdown />
        </div>
        <img
          className="hidden sm:block mr-10 ml-auto sm:ml-0"
          src="/assets/images/cart.svg"
          alt=""
        />
        {isAuthenticated && (
          <>
            <div className="hidden sm:block h-10 w-10 rounded-full bg-textGrey"></div>
            <div className="hidden sm:block">
              <UserDropdown />
            </div>
          </>
        )}
        {!isAuthenticated && (
          <div className="flex gap-x-[25px]">
            <Link to="/sign-in">
              <button className="outline-button">SIGN IN</button>
            </Link>
            <Link to="/sign-up">
              <button className="filled-button">SIGN UP</button>
            </Link>
          </div>
        )}
      </div>
      <div className="sm:hidden ml-auto">
        {!isAuthenticated && (
          <div className="flex gap-x-[25px]">
            <Link to="/sign-in">
              <button className="outline-button">SIGN IN</button>
            </Link>
            {/* <Link to="/sign-up">
                <button className="py-2 bg-primary text-white px-4">SIGN UP</button>
              </Link> */}
          </div>
        )}
        {isAuthenticated && <MobileNav source="buyer" isDashboard />}
      </div>
    </nav>
  );
};

export default DashboardNavBar;
