import * as React from "react";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import CustomBadge from "../../../../common/components/CustomBadge";
import { Link } from "react-router-dom";
import CustomTable, {
  HeadCell,
} from "../../../../common/components/CustomTable/CustomTable";
import {
  IAPIResponse,
  IFilterFetch,
  IListing,
  IListings,
  IPropertyListing,
} from "../../../../common/utils/interfaces";
import { getListingsList } from "../../../../../apis/admin/listings";
import { formatDate } from "../../../../common/utils/helpers";
import { getMyListings } from "../../../../../apis/listings/sellers";

interface Data {
  id: number;
  calories: number;
  carbs: "approved" | "pending" | "rejected";
  fat: number;
  name: string;
  actions: string;
}

type Props = {
  setDetailData: (arg: IListings) => void;
  filter: IFilterFetch;
};

function createData(
  id: number,
  name: string,
  calories: number,
  fat: number,
  carbs: "approved" | "pending" | "rejected",
  actions: string
): Data {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    actions,
  };
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  /* {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Application Type",
  }, */
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Submission Date",
  },
  {
    id: "carbs",
    numeric: false,
    disablePadding: false,
    label: "Verification Status",
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

const PropertyListingsTable: React.FC<Props> = ({
  setDetailData,
  filter,
}: Props) => {
  const [tableData, setTableData] = React.useState<IListings[]>([]);

  const tableTitle = { mainText: "Listing Requests", subText: "requests" };

  React.useEffect(() => {
    fetchTableData();
  }, [filter]);

  const fetchTableData = async () => {
    const result: IAPIResponse | undefined = await getMyListings(filter);
    if (result) {
      const { data, count } = result;
      setTableData(data);
    }
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const selectData = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    selectedData: IListings
  ) => {
    evt.stopPropagation();

    setDetailData(selectedData);
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: any;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.name}
      </TableCell>
      {/* temporarily hidden */}
      {/* <TableCell align="right">Standard</TableCell> */}
      <TableCell align="right">{formatDate(row.submission_date)}</TableCell>
      <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.status)}>{row.status}</CustomBadge>
      </TableCell>
      <TableCell align="center">
        {/* <Link
          to="/admin/property-listings/application"
          className="hover_underline"
        > */}
        <Typography
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            gap: 10,
          }}
          onClick={(evt) => selectData(evt, row)}
        >
          View details
        </Typography>
        {/* </Link> */}
      </TableCell>
    </>
  );

  return (
    <CustomTable
      tableTitle={tableTitle}
      headCells={headCells}
      rows={tableData}
      mappedTableRows={MappedTableRows}
    />
  );
};

export default PropertyListingsTable;
