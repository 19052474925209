import HomeLayout from "../../commonComponents/HomeLayout";

const About = () => {
  return (
    <HomeLayout>
      <section className="items-center px-4 sm:px-10 lg:px-[174px] pt-[30px] text-center">
        <h2 className="h2">About</h2>
        <p className="body-description mt-8 text-justify">
          Home and Abroad (www.homeandabroad.ng) is an initiative by NIDCOM and
          powered by Sydani Group. It is a trustworthy real estate platform
          dedicated to empowering Nigerians in the diaspora to invest in secure,
          vetted, and government-approved properties in Nigeria. Our goal is to
          bridge the gap between Nigerians abroad and the local real estate
          market, ensuring a seamless and transparent investment experience. In
          collaboration with Sydani Group, a technology-driven firm known for
          its innovative solutions, and in partnership with the Nigerian
          government through the Nigerians in Diaspora Commission (NiDCOM), is
          committed to creating a platform that prioritizes security, trust, and
          convenience. <br /> <br /> This strategic alliance combines cutting-edge technology
          with governmental oversight, enhancing the reliability of our services
          and reinforcing our commitment to transparency. Home and Abroad offers
          diverse real estate opportunities, each meticulously vetted and
          verified for authenticity and compliance with local regulations. We
          understand that investing from abroad can be daunting, so we strive to
          make the process as straightforward and secure as possible. At Home
          and Abroad, we believe in empowering Nigerians abroad to maintain a
          connection to their roots while confidently investing in their future.
          By providing access to trustworthy information and government-backed
          opportunities, we aim to foster a sense of security, trust, and
          renewed hope, encouraging our clients to engage with the Nigerian real
          estate market.
        </p>
      </section>
    </HomeLayout>
  );
};

export default About;
