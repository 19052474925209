import axios from "axios";
import { AppDispatch } from "../../store/store"; // Your store setup where AppDispatch is defined
import {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  signUpStart,
  signUpSuccess,
  signUpFailure,
} from "../../store/slices/buyer/userSlice";
import apiRoutes from "../../views/common/utils/apiRoutes";
import { logoutClearLocalStorage } from "../../views/common/utils/helpers";

export const loginUser =
  (email: string, password: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(loginStart());
      const response = await axios.post(apiRoutes.accounts.login, {
        email,
        password,
      });
      dispatch(loginSuccess(response.data.user));
      const user = {
        first_name: response.data.data.first_name,
        last_name: response.data.data.last_name,
        email: response.data.data.email,
        "user-types": response.data.data.user_types,
      };
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("access_token", response.data.data.access_token);
      localStorage.setItem("refresh_token", response.data.data.refresh_token);
      return user;
    } catch (error: any) {
      dispatch(loginFailure(error.response?.data?.message || "Login failed"));
      // return false
    }
  };

export const signUpUser =
  (
    email: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    country_code: string,
    password1: string,
    password2: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(signUpStart());
      const response = await axios.post(
        `${apiRoutes.accounts.register}`,
        {
          email,
          first_name,
          last_name,
          phone_number,
          country_code,
          password1: password1,
          password2: password2,
        },
        {
          params: { ut: "buyer" },
        }
      );
      dispatch(signUpSuccess(response.data.user));

      return response.data;
    } catch (error: any) {
      dispatch(signUpFailure(error.response?.data?.message || "Login failed"));
      // return false
    }
  };

export const forgotUserPassword =
  (email: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post(apiRoutes.accounts.forgotPassword, {
        email,
      });
      return response.status;
    } catch (error: any) {
      dispatch(loginFailure(error.response?.data?.message || "Error occurred"));
    }
  };

export const verifyUserOtp =
  (email: string, otp: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(loginStart());
      const response = await axios.post(apiRoutes.accounts.verifyAuthOtp, {
        email,
        otp,
      });
      dispatch(loginSuccess(response.data.user));
      const user = {
        first_name: response.data.data.first_name,
        last_name: response.data.data.last_name,
        email: response.data.data.email,
        "user-types": response.data.data.user_types,
      };
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("access_token", response.data.data.access_token);
      localStorage.setItem("refresh_token", response.data.data.refresh_token);
      return user;
    } catch (error: any) {
      dispatch(loginFailure(error.response?.data?.message || "Login failed"));
    }
  };

export const verifyRegistrationOtp =
  (email: string, otp: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(loginStart());
      const response = await axios.post(
        apiRoutes.accounts.verifyRegistrationOtp,
        {
          email,
          otp,
        }
      );
      dispatch(loginSuccess(response.data.user));
      if (response.data.data.access) {
        localStorage.setItem("access_token", response.data.data.access);
        localStorage.setItem("refresh_token", response.data.data.refresh);
      }
      return response.data.status;
    } catch (error: any) {
      dispatch(loginFailure(error.response?.data?.message || "Login failed"));
    }
  };

export const resendUserOtp =
  (email: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post(apiRoutes.accounts.resendOtp, {
        email,
      });
      dispatch(loginSuccess({}));
      return response.data.status;
    } catch (error: any) {
      dispatch(loginFailure("Could not resend OTP"));
    }
  };

export const logoutUser = () => (dispatch: AppDispatch) => {
  // localStorage.removeItem("user");
  logoutClearLocalStorage();
  dispatch(logout());
};

export const sellerSignUp =
  (
    email: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    country_code: string,
    password1: string,
    password2: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(signUpStart());
      const response = await axios.post(apiRoutes.accounts.sellerRegister, {
        email,
        first_name,
        last_name,
        phone_number,
        country_code,
        password1: password1,
        password2: password2,
      });
      dispatch(signUpSuccess(response.data.user));

      const access_token = response.data.data.access;
      const refresh_token = response.data.data.refresh;

      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      return response.data;
    } catch (error: any) {
      dispatch(signUpFailure(error.response?.data?.message || "Login failed"));
      // return false
    }
  };

export const addCompanyInfo =
  (
    company_name: string,
    company_registration_number: string,
    business_type: string,
    website_url: string,
    address: string,
    city: string,
    lga: string,
    state: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      // @ts-ignore
      const token = localStorage.getItem("access_token");
      dispatch(signUpStart());
      const response = await axios.put(
        apiRoutes.accounts.addCompanyInfo,
        {
          company_name,
          company_registration_number,
          business_type,
          website_url,
          address,
          city,
          lga,
          state,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(signUpSuccess(response.data.user));
      return { status: response.data.status, data: response.data.data };
    } catch (error: any) {
      dispatch(signUpFailure(error.response?.data?.message || "Login failed"));
      // return false
    }
  };

export const uploadDocuments =
  (files: any) => async (dispatch: AppDispatch) => {
    try {
      // @ts-ignore
      const token = localStorage.getItem("access_token");
      const formData = new FormData();
      Object.keys(files).forEach((fileName) => {
        formData.append(fileName, files[fileName]); // Append file under its specific name
      });
      const response = await axios.put(
        apiRoutes.accounts.uploadFiles,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.status;
    } catch (error: any) {
      dispatch(signUpFailure(error.response?.data?.message || "Login failed"));
    }
  };

export const fetchStates = async () => {
  try {
    // @ts-ignore
    const token = localStorage.getItem("access_token");
    const response = await axios.get(apiRoutes.locations.states, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error: any) {}
};
export const fetchTowns = async (state_id: string) => {
  try {
    // @ts-ignore
    const token = localStorage.getItem("access_token");
    const response = await axios.get(apiRoutes.locations.towns, {
      params: {
        state_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error: any) {}
};
export const fetchLgas = async (state_id: string) => {
  try {
    // @ts-ignore
    const token = localStorage.getItem("access_token");
    const response = await axios.get(apiRoutes.locations.lgas, {
      params: {
        state_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error: any) {}
};

export const verifyAuth = () => (dispatch: AppDispatch) => {
  dispatch(loginStart());
  const user = JSON.parse((localStorage.getItem("user") || null) as any);

  if (user !== null) {
    dispatch(loginSuccess(user));
  }
};

export const refreshAccessToken = async () => {
  try {
    const refresh = localStorage.getItem("refresh_token");
    const { data: response } = await axios.post(
      apiRoutes.accounts.refreshAccessToken,
      {
        refresh,
      }
    );

    if (response.access) {
      const { access: access_token, refresh_token: newRefreshToken } = response;
      // Store the new access and refresh tokens.
      localStorage.setItem("access_token", access_token);
      !!newRefreshToken &&
        localStorage.setItem("refresh_token", newRefreshToken);

      return { access_token };
    } else {
      return { error: "" };
    }
  } catch (error) {
    return { error: "" };
  }
};
