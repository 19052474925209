import React, { ChangeEvent } from "react";
import DashboardLayout from "../../../common/components/DashboardLayout";
import { sellerDashboardNavList } from "../../../common/global/DashboardRoutes";

const SellerDashboardLayout = ({
  children,
  noPadding = false,
  hasHeader = false,
  pageHeader,
  pageBase,
  searchPlaceholder,
  onFilter,
}: {
  noPadding?: boolean;
  hasHeader?: boolean;
  children: JSX.Element;
  pageHeader?: { mainText: string; subText?: string; flankRight?: string };
  pageBase?: JSX.Element;
  searchPlaceholder?: string;
  onFilter?: (evt: ChangeEvent<HTMLInputElement>, state: string) => void;
}) => {
  return (
    <DashboardLayout
      section="seller"
      noPadding={noPadding}
      hasHeader={hasHeader}
      navigationMenu={sellerDashboardNavList}
      pageHeader={pageHeader}
      pageBase={pageBase}
    >
      {children}
    </DashboardLayout>
  );
};

export default SellerDashboardLayout;
