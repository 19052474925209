import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListingInterface, ListingsInterface } from "../../../types";

interface ListingsState {
  loading: boolean;
  listings: ListingsInterface[];
  error: string | null;
}

const initialState: ListingsState = {
  loading: false,
  listings: [],
  error: null,
};

const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    fetchListings(state) {
      state.loading = true;
      state.error = null;
    },
    fetchListingsSuccess(state, action: PayloadAction<ListingsInterface[]>) {
        state.loading = false
        state.error = null
        state.listings = action.payload
    },
    fetchListingsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload
    },
  },
});

export const {  fetchListings, fetchListingsFailure, fetchListingsSuccess } = listingsSlice.actions

export default listingsSlice.reducer
