import { Slider } from "@mui/material";
import React, { useState } from "react";
import filterData from "../../data/filterData.json";

interface Props {
  closeFilter: () => void;
  showStateModal: () => void;
}
const FilterSection: React.FC<Props> = ({ closeFilter, showStateModal }) => {
  const [amountRange, setAmountRange] = useState<number[]>([0, 0]);
  const handleChange = (event: Event, newValue: number | number[]) => {
    setAmountRange(newValue as number[]);
  };
  function valueLabelFormat(value: number) {
    return `$${value * 10}k`;
  }
  const {
    propertyType,
    verification,
    discount,
    facilities,
    bathroom,
    geographicVerification,
    bedroom,
    conditioning,
    furnishing,
  } = filterData;
  return (
    <div className=" max-w-[100%] z-50 h-full bg-white flex flex-col gap-y-5 sm:gap-y-[60px]">
      <div>
        <div className="bg-primary rounded-t-lg flex items-center justify-between py-5 px-[30px]">
          <h3 className="h3 !text-textWhite">Categories</h3>
          <img
            onClick={closeFilter}
            className="cursor-pointer"
            src="/assets/images/close-circle-white.svg"
            alt=""
          />
        </div>
        <div className="card-shadow py-5 px-[30px]">
          <p className="subheadings mb-[10px]">Location</p>
          <div
            onClick={showStateModal}
            className="flex justify-between pl-[15px] text-[16px] cursor-pointer"
          >
            <p className="">Nigeria</p>
            <img src="/assets/images/chevron-right.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="card-shadow rounded-lg py-5 px-[30px]">
        <div className="flex justify-between items-center">
          <p className="subheadings ">Prices</p>
          <p className="subheadings !text-primary">
            ${amountRange[0] * 10}k - $
            {`${amountRange[1] === 100 ? `1m` : `${amountRange[1] * 10}k`}`}
          </p>
        </div>
        <Slider
          size="medium"
          value={amountRange}
          onChange={handleChange}
          aria-label="Medium"
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
        />
        <div className="flex justify-between subheadings">
          <span className="text-primary/25">$0</span>
          <span className="text-primary/25">$1m</span>
        </div>
        <button className="outline-button mb-5 mt-[30px]">MORE</button>
        <div className="flex justify-between text-[18px]">
          <span>Clear</span>
          <span className="!text-primary">Save</span>
        </div>
      </div>
      <div className="card-shadow rounded-lg p-[30px]">
        <p className="subheadings mb-[10px]">Property Type</p>
        <div className="flex flex-col gap-y-[10px] body-description text-textBlack">
          <FilterType filterType={propertyType} />
        </div>
      </div>
      <div className="card-shadow rounded-lg p-[30px]">
        <p className="subheadings mb-[10px]">Bedroom</p>
        <div className="flex flex-col gap-y-[10px] body-description text-textBlack">
          <FilterType filterType={bedroom} />
        </div>
      </div>
      <div className="card-shadow rounded-lg p-[30px]">
        <p className="subheadings mb-[10px]">Bathroom</p>
        <div className="flex flex-col gap-y-[10px] body-description text-textBlack">
          <FilterType filterType={bathroom} />
        </div>
      </div>
      <div className="card-shadow rounded-lg p-[30px]">
        <p className="subheadings mb-[10px]">Conditioning</p>
        <div className="flex flex-col gap-y-[10px] body-description text-textBlack">
          <FilterType filterType={conditioning} />
        </div>
      </div>
      <div className="card-shadow rounded-lg p-[30px]">
        <p className="subheadings mb-[10px]">Furnishing</p>
        <div className="flex flex-col gap-y-[10px] body-description text-textBlack">
          <FilterType filterType={furnishing} />
        </div>
      </div>
      <div className="card-shadow rounded-lg p-[30px]">
        <p className="subheadings mb-[10px]">Facilities</p>
        <div className="flex flex-col gap-y-[10px] body-description text-textBlack">
          <FilterType filterType={facilities} />
        </div>
      </div>
      <div className="card-shadow rounded-lg p-[30px]">
        <p className="subheadings mb-[10px]">Discount</p>
        <div className="flex flex-col gap-y-[10px] body-description text-textBlack">
          <FilterType filterType={discount} />
        </div>
      </div>
      <div className="card-shadow rounded-lg p-[30px]">
        <p className="subheadings mb-[10px]">Verification</p>
        <div className="flex flex-col gap-y-[10px] body-description text-textBlack">
          <FilterType filterType={verification} />
        </div>
      </div>
      <div className="flex px-[30px] pb-10 justify-between items-center">
        <span className="subheadings">Clear</span>
        <button className="filled-button">SHOW 12356</button>
      </div>
    </div>
  );
};

export default FilterSection;

interface FilterTypeProps {
  filterType: string[];
}
const FilterType: React.FC<FilterTypeProps> = ({ filterType }) => {
  const [showMore, setShowMore] = useState(false);
  const filterLength = showMore ? filterType : filterType.slice(0, 2);
  return (
    <div>
      <div className="flex flex-col gap-y-[10px]">
        {filterLength.map((type) => (
          <div className="flex py-1 pl-[10px] items-center gap-x-[13px]">
            <input type="checkbox" name="" id="" />
            <span className="body-description">{type}</span>
          </div>
        ))}
      </div>
      <button onClick={() => setShowMore(!showMore)} className="outline-button mt-[30px]">
        {showMore ? "LESS" : "MORE"}
      </button>
      <div className="flex mt-5 justify-between font-medium">
        <span>Clear</span>
        <span className="text-primary">Save</span>
      </div>
    </div>
  );
};
