import { Box, Button, Collapse, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IUserType } from "../../common/utils/interfaces";
import { ExpandLess, ExpandMore, Person } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { NavIconLogout } from "../../common/components/LayoutIcons";
import {
  adminDashboardNavList,
  buyerDashboardNavList,
  INavItem,
  sellerDashboardNavList,
} from "../../common/global/DashboardRoutes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { logoutUser } from "../../../apis/accounts/auth";

const MobileNav = ({
  source,
  isDashboard,
}: {
  source: IUserType;
  isDashboard?: boolean;
}) => {
  const userState = useSelector((state: RootState) => state.user as any);

  const storedUser = JSON.parse((localStorage.getItem("user") || null) as any);

  const dispatch: AppDispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [toggleSubMenu, setToggleSubMenu] = useState<any>({});
  const [userNames, setUserNames] = useState<any>({});

  useEffect(() => {
    if (userState?.user?.first_name) {
      setUserNames({
        first_name: userState?.user?.first_name,
        last_name: userState?.user?.last_name,
      });
    }
    if (storedUser?.first_name) {
      setUserNames({
        first_name: storedUser?.first_name,
        last_name: storedUser?.last_name,
      });
    }
  }, [userState?.user, storedUser]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const logOut = async () => {
    dispatch(logoutUser());
  };

  const DrawerList = (
    <Box sx={{ width: 250, height: "100%" }} role="presentation">
      <div
        className="flex flex-col gap-y-10 pt-10 pl-6"
        style={{
          height: "100%",
          paddingBottom: 30,
        }}
      >
        {navList(source, isDashboard)
          .filter((item: INavItem) => item.title !== "Logout")
          .map((item: INavItem, index) =>
            !item.sub ? (
              <Link key={index} to={item.url}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 15,
                    ...(isDashboard && item.title === "Transactions"
                      ? { borderBottom: "solid 1px #f6f6f6", paddingBottom: 30 }
                      : {}),
                  }}
                >
                  <span className="_green-img">{item.icon}</span>
                  <span className="text-[14px]">{item.title}</span>
                </div>
              </Link>
            ) : (
              <div key={index}>
                <div
                  onClick={() =>
                    setToggleSubMenu((state: any) => ({
                      ...state,
                      [item.title]: !state[item.title],
                    }))
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 15,
                  }}
                >
                  <span className="_green-img">{item.icon}</span>
                  <span className="text-[14px]">
                    {item.title}
                    {toggleSubMenu[item.title] ? (
                      <ExpandLess style={{ marginLeft: 10 }} />
                    ) : (
                      <ExpandMore style={{ marginLeft: 10 }} />
                    )}
                  </span>
                </div>
                <Collapse in={toggleSubMenu[item.title]}>
                  <div
                    style={{
                      marginLeft: 15,
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      marginTop: 10,
                    }}
                  >
                    {item.sub.map((subItem, subIndex: number) => (
                      <Link key={`${index}-${subIndex}`} to="/">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 15,
                          }}
                        >
                          <span className="_green-img">{subItem.icon}</span>
                          <span className="text-[14px]">{subItem.title}</span>
                        </div>
                      </Link>
                    ))}
                  </div>
                </Collapse>
              </div>
            )
          )}
        {source !== "unauthenticated" && (
          <button>
            <div
              onClick={logOut}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 15,
              }}
            >
              <span className="_green-img">
                <NavIconLogout />
              </span>
              <span onClick={logOut} className="text-[14px]">
                Logout
              </span>
            </div>
          </button>
        )}
      </div>
    </Box>
  );
  // @ts-ignore
  return (
    <div>
      <Button
        onClick={toggleDrawer(true)}
        style={{ minWidth: "unset", paddingLeft: 10, paddingRight: 10 }}
      >
        <img src="/assets/images/menu.svg" alt="" style={{ width: 23 }} />
      </Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "18.5px 20px",
            gap: 10,
            boxShadow: "0 1px 6px #eee",
            maxWidth: 250,
          }}
        >
          <div className="_avatar" style={{ width: 30, height: 30 }}>
            <Person style={{ fontSize: 60 }} />
          </div>
          {/* <Link to="/dashboard"> */}
          <Typography
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize: 12,
            }}
          >
            {/* @ts-ignore */}
            {userNames.first_name} {userNames.last_name}
          </Typography>
          {/* </Link> */}
        </div>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default MobileNav;

const navList = (source: IUserType, isDashboard?: boolean) => {
  switch (source) {
    case "admin":
      return adminDashboardNavList;

    case "seller":
      return sellerDashboardNavList;

    case "unauthenticated":
      return [
        {
          title: "Buy Now",
          icon: null,
          url: "/property-search",
        },
        {
          title: "FAQs",
          icon: null,
          url: "/faqs",
        },
        {
          title: "Support",
          icon: null,
          url: "",
          sub: [
            {
              title: "Chat",
              icon: null,
              url: "",
            },
            {
              title: "+234 703 987 9292",
              icon: null,
              url: "me.wa/+234 703 987 9292",
            },
            {
              title: "+234 703 987 9292",
              icon: null,
              url: "tel:+234 703 987 9292",
            },
            {
              title: "NiDCOM@staff.com",
              icon: null,
              url: "mailto:NiDCOM@staff.com",
            },
          ],
        },
        {
          title: "Sign Up",
          icon: null,
          url: "/sign-up",
        },
        {
          title: "Sign In",
          icon: null,
          url: "/sign-in",
        },
      ];
    default:
      return [
        ...(isDashboard
          ? buyerDashboardNavList
          : [
              {
                title: "Dashboard",
                icon: null,
                url: "/dashboard",
              },
            ]),
        {
          title: "Buy Now",
          icon: null,
          url: "",
        },
        // {
        //   title: "Installments",
        //   icon: null,
        //   url: "",
        // },
        // {
        //   title: "Mortgage",
        //   icon: null,
        //   url: "",
        // },
        {
          title: "FAQs",
          icon: null,
          url: "",
        },
        {
          title: "Support",
          icon: null,
          url: "",
          sub: [
            {
              title: "Chat",
              icon: null,
              url: "",
            },
            {
              title: "+234 703 987 9292",
              icon: null,
              url: "me.wa/+234 703 987 9292",
            },
            {
              title: "+234 703 987 9292",
              icon: null,
              url: "tel:+234 703 987 9292",
            },
            {
              title: "NiDCOM@staff.com",
              icon: null,
              url: "mailto:NiDCOM@staff.com",
            },
          ],
        },
      ];
  }
};
