import { PieChart } from "@mui/x-charts";
import HomeLayout from "../../commonComponents/HomeLayout";
import FeaturedProperty from "../../components/FeaturedProperty";
import mockData from "../../data.json";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ImageGallery from "../../modals/ImageGallery";
import { Typography } from "@mui/material";
import MyGoogleMap from "../../components/GoogleMap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { getListing, getListings } from "../../../../apis/listings/buyer";
import { ListingInterface } from "../../../../types";
import { IPropertyFeatures } from "../../../common/utils/interfaces";
import { categorizeFeatures } from "../../../common/utils/helpers";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";
import VirtualTour from "../../modals/payment-modals/VirtualTour";
import PropertyVerification from "../../modals/payment-modals/PropertyVerification";
import PhysicalTour from "../../modals/payment-modals/PhysicalTour";
import ScheduleTour from "../../modals/payment-modals/ScheduleTour";
import PaymentSuccessful from "../../modals/payment-modals/PaymentSuccessful";
import PayWithCard from "../../modals/payment-modals/PayWithCard";

const Details = () => {
  const { listingId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const listing = useSelector((state: RootState) => state.listing);
  const currentListing = listing.listing;
  const listings = useSelector((state: RootState) => state.listings);
  const [showModal, setShowModal] = useState(false);
  const [showTourPopup, setShowTourPopup] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const screenSize = window.innerWidth;
  const navigate = useNavigate();

  const [paymentModals, setPaymentModals] = useState({
    virtualTour: false,
    propertyVerification: false,
    scheduleTour: false,
    physicalTour: false,
    paymentSuccessful: false,
    payWithCard: false,
  });

  const openPaymentModal = (name: string) => {
    const newObj: any = {};
    Object.keys(paymentModals).forEach((key: string) => {
      newObj[key] = false;
    });
    setPaymentModals(newObj);
    setPaymentModals((prev) => ({
      ...prev,
      [name]: true,
    }));
  };
  const closePaymentModal = (name: string) => {
    setPaymentModals((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const formatToNaira = (amount: number) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(amount);
  };

  useEffect(() => {
    if (listingId) dispatch(getListing(listingId));
  }, [listingId, dispatch]);

  useEffect(() => {
    dispatch(getListings());
  }, [dispatch]);
  return (
    <HomeLayout>
      <div className="mt-5"></div>
      {!listing.loading && listing.listing === null && (
        <span className="body-description">
          Listing with that id does not exist
        </span>
      )}
      {!listing.loading && listing.listing && (
        <div className="px-6 sm:px-8 lg:px-[81px]">
          <div className="grid sm:grid-cols-2 gap-y-5 gap-x-[21px] overflow-y-hidden">
            <div className="rounded-xl h-[225px] sm:h-[400px] lg:h-[528px]">
              <img
                className="h-full w-full rounded-xl object-cover"
                src={listing.listing.files[0].file}
                alt=""
              />
            </div>
            <div className="h-fit sm:h-[400px] lg:h-[528px] grid grid-cols-2 gap-4 sm:gap-[18px]">
              <img
                src={listing.listing.files[1].file}
                className="h-full w-full rounded-xl sm:hidden"
                alt=""
              />
              {listing.listing.files.slice(1, 4).map((file) => (
                <img
                  key={file.file}
                  className="hidden sm:block h-full w-full rounded-xl"
                  src={file.file}
                  alt=""
                />
              ))}
              {listing.listing.files.length > 4 && (
                <div className="relative sm:h-full aspect-square sm:aspect-auto w-full rounded-xl">
                  <div
                    onClick={() => setShowModal(true)}
                    className="absolute w-full h-full cursor-pointer flex flex-col items-center justify-center rounded-xl bg-[#1C1B1F]/40"
                  >
                    <p className="text-textWhite font-semibold text-[43px]">
                      {screenSize > 640
                        ? listing.listing.files.length - 4
                        : listing.listing.files.length - 2}
                      +
                    </p>
                    <p className="text-textWhite font-medium text-center text-[22px]">
                      Photos
                    </p>
                  </div>
                  <img
                    className="h-full w-full rounded-xl"
                    src={listing.listing.files[4].file}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
          <div className="lg:flex mt-[55px] gap-x-[76px]">
            <section className="flex-1">
              <h1 className="h1 !font-semibold">{listing.listing.name}</h1>
              <p className="text-justify mt-[10px] body-description">
                {listing.listing.description}
              </p>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-y-[30px] mt-[25px] mb-6 body-description">
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/assets/images/bathroom.svg" alt="" />
                  </div>
                  <span>{listing.listing.bathrooms} bathrooms</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/assets/images/bedroom.svg" alt="" />
                  </div>
                  <span>{listing.listing.bedrooms} bedrooms</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/assets/images/distance.svg" alt="" />
                  </div>
                  <span>{listing.listing.square_footage}sqkm</span>
                </span>
                {/* <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/assets/images/gym.svg" alt="" />
                  </div>
                  <span>Gym</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/assets/images/garage.svg" alt="" />
                  </div>
                  <span>Garage</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/assets/images/pool.svg" alt="" />
                  </div>
                  <span>Pool</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/assets/images/garden.svg" alt="" />
                  </div>
                  <span>Garden</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/assets/images/location.svg" alt="" />
                  </div>
                  <span>Urban</span>
                </span> */}
              </div>
              <button
                onClick={() => setShowMore(!showMore)}
                className={`outline-button !font-semibold ${
                  showMore ? "hidden" : ""
                }`}
              >
                SEE MORE
              </button>
              {showMore && (
                <>
                  <MoreDetails listing={listing.listing} />
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className={`outline-button mt-[25px] mb-8 !font-semibold ${
                      !showMore ? "hidden" : ""
                    }`}
                  >
                    SEE LESS
                  </button>
                </>
              )}
            </section>
            <aside className="max-w-full sm:grid gap-x-6 grid-cols-2 w-[437px] sm:w-full lg:w-[437px] lg:block mt-6 sm:mt-0">
              <div className="px-[32px] py-[25px] card-shadow">
                <div className="flex">
                  <p>Price</p>
                  <img
                    className="ml-[62px]"
                    src={"/assets/images/heart.svg"}
                    alt=""
                  />
                </div>
                <p className="h2 text-textBlack flex gap-x-1 items-center">
                  {formatToNaira(listing.listing.price)}
                </p>
                <button
                  onClick={() => setShowTourPopup(!showTourPopup)}
                  className="outline-button !border-textBlack !text-textBlack w-full mt-5"
                >
                  PROPERTY TOUR
                </button>
                <div
                  className={`card-shadow text-[14px] duration-500 py-5 px-[26px] origin-top ${
                    showTourPopup
                      ? "scale-y-100 my-5 h-fit"
                      : "scale-y-0 h-0 hidden"
                  }`}
                >
                  <p
                    className="py-[10px] px-4 cursor-pointer"
                    onClick={() => openPaymentModal("virtualTour")}
                  >
                    Virtual Tour
                  </p>
                  <p
                    className="py-[10px] px-4 cursor-pointer"
                    onClick={() => openPaymentModal("scheduleTour")}
                  >
                    Physical Tour
                  </p>
                </div>
                <button
                  onClick={() => openPaymentModal("propertyVerification")}
                  className="outline-button !border-textBlack !text-textBlack w-full my-[10px] "
                >
                  PROPERTY VERIFICATION
                </button>
                <Link to="/buy-property">
                  <button className="filled-button w-full">BUY PROPERTY</button>
                </Link>
              </div>
              <div className="px-[32px] py-[25px] h-fit card-shadow mt-[15px]">
                <p className="text-[#686868] text-[16px]">Need help?</p>
                <div className="grid grid-cols-2 gap-x-[18px] mt-[30px]">
                  <a href="tel:+123456679" className="outline-button">CALL</a>
                  <button className="filled-button">
                    <span>MESSAGE</span>
                  </button>
                </div>
              </div>
              {showMore && (
                <>
                  <h2 className="h2 mt-4 col-span-2">Properties for You</h2>
                  <div className="grid col-span-2 gap-x-5 sm:grid-cols-2 lg:grid-cols-1 gap-y-9 mt-7">
                    <FeaturedProperty listing={listings.listings[0]} />
                  </div>
                </>
              )}
            </aside>
          </div>
          <p className="text-[20px] font-medium text-textBlack mb-[15px] mt-[35px]">
            Location
          </p>
          <div className="flex items-center gap-x-[15px] text-[16px]">
            <img src="/assets/images/location.svg" alt="location" />
            <span>{listing.listing.state}</span>
          </div>
          <div className="sm:flex gap-x-10 mt-[30px]">
            <div className="flex-1 card-shadow">
              <MyGoogleMap />
            </div>
            <div className="p-10 card-shadow rounded-[10px]">
              <div className="mt-5 flex flex-col gap-y-6">
                <div className="flex gap-x-[15px]">
                  <img src="/assets/images/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Certified Developer
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/assets/images/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Track Record
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/assets/images/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Trusted by Diaspora Investors
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/assets/images/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">Title Deeds</p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/assets/images/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Certified Land Ownership
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/assets/images/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    No legal Disputes
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/assets/images/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Environmentally Compliant
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/assets/images/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Tax Compliance
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <section className="grid sm:grid-cols-3 gap-y-4 gap-x-[37px] my-[35px]">
            <div className="h-[309px] relative rounded-lg overflow-hidden">
              <div className="absolute h-full w-full flex flex-col gap-y-2 items-center justify-center bg-black/50">
                <img src="/assets/images/virtual-tour.svg" alt="" />
                <p className="text-textWhite text-[36px] font-semibold">
                  Virtual Tour
                </p>
              </div>
              <img
                src="/assets/images/tour.png"
                className="h-full w-full"
                alt=""
              />
            </div>
            <div className="h-[309px] relative rounded-lg overflow-hidden">
              <div className="absolute h-full w-full flex flex-col gap-y-2 items-center justify-center bg-black/50">
                <img src="/assets/images/live-video.svg" alt="" />
                <p className="text-textWhite text-[36px] font-semibold">
                  Live Video
                </p>
              </div>
              <img
                src="/assets/images/live-video.png"
                className="h-full w-full"
                alt=""
              />
            </div>
            <div className="h-[309px] relative rounded-lg overflow-hidden">
              <div className="absolute h-full w-full flex flex-col gap-y-2 items-center justify-center bg-black/50">
                <img src="/assets/images/floor-plan.svg" alt="" />
                <p className="text-textWhite text-[36px] font-semibold">
                  Floor Plan
                </p>
              </div>
              <img
                src="/assets/images/floor-plan.png"
                className="h-full w-full"
                alt=""
              />
            </div>
          </section> */}
          {/* <section className="py-[35px] flex flex-col gap-y-[35px]">
          <div className="flex flex-col gap-y-3 sm:flex-row justify-between">
            <div className="max-w-[496px] flex-1">
              <h2 className="h2">Monthly payment calculator</h2>
              <div className="my-[25px]">
                <div className="flex gap-x-[10px]">
                  <span className="text-[20px] text-textBlack/50">
                    Estimated payment
                  </span>
                  <img src="/assets/images/info-circle.svg" alt="" />
                </div>
                <p className="text-black text-[22px] font-medium">$6,455/mo</p>
              </div>
              <button className="outline-button w-full">
                CUSTOMIZE THIS PAYMENT
              </button>
            </div>
            <div className="sm:flex overflow-x-hidden">
              <PieChart
                series={[
                  {
                    data: [
                      {
                        id: 0,
                        value: 10,
                        label: "Principal & Interest",
                        color: "#65D26D",
                      },
                      {
                        id: 1,
                        value: 15,
                        label: "All taxes & Insurance",
                        color: "#066D0E",
                      },
                    ],
                    innerRadius: 80,
                  },
                ]}
                slotProps={{
                  legend: {
                    hidden: true,
                  },
                }}
                width={400}
                height={200}
              />
              <div className="flex flex-col gap-y-[25px]">
                <div className="flex items-center">
                  <div className="h-5 w-5 bg-primary rounded"></div>
                  <p className="ml-[30px] mr-[44px] text-[22px] w-[124px]">
                    Principal & Interest
                  </p>
                  <p className="text-textBlack font-semibold text-[26px]">
                    $6,455
                  </p>
                </div>
                <div className="flex items-center">
                  <div className="h-5 w-5 bg-[#65D26D] rounded"></div>
                  <p className="ml-[30px] mr-[44px] text-[22px] w-[124px]">
                    All Taxes & Insurance
                  </p>
                  <p className="text-textBlack font-semibold text-[26px]">
                    $6,455
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-3 sm:flex-row justify-between">
            <div className="max-w-[496px] flex-1">
              <h2 className="h2">Monthly payment calculator</h2>
              <div className="my-[25px]">
                <div className="flex gap-x-[10px]">
                  <span className="text-[20px] text-textBlack/50">
                    Estimated payment
                  </span>
                  <img src="/assets/images/info-circle.svg" alt="" />
                </div>
                <p className="text-black text-[22px] font-medium">$6,455/mo</p>
              </div>
              <button className="outline-button w-full">
                CUSTOMIZE THIS PAYMENT
              </button>
            </div>
            <div className="sm:flex overflow-x-hidden">
              <PieChart
                series={[
                  {
                    data: [
                      {
                        id: 0,
                        value: 10,
                        label: "Principal & Interest",
                        color: "#65D26D",
                      },
                      {
                        id: 1,
                        value: 15,
                        label: "All taxes & Insurance",
                        color: "#066D0E",
                      },
                    ],
                    innerRadius: 80,
                  },
                ]}
                slotProps={{
                  legend: {
                    hidden: true,
                  },
                }}
                width={400}
                height={200}
              />
              <div className="flex flex-col gap-y-[25px]">
                <div className="flex items-center">
                  <div className="h-5 w-5 bg-primary rounded"></div>
                  <p className="ml-[30px] mr-[44px] text-[22px] w-[124px]">
                    Principal & Interest
                  </p>
                  <p className="text-textBlack font-semibold text-[26px]">
                    $6,455
                  </p>
                </div>
                <div className="flex items-center">
                  <div className="h-5 w-5 bg-[#65D26D] rounded"></div>
                  <p className="ml-[30px] mr-[44px] text-[22px] w-[124px]">
                    All Taxes & Insurance
                  </p>
                  <p className="text-textBlack font-semibold text-[26px]">
                    $6,455
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
          {/* <section className="my-[35px]">
            <div className="flex items-center justify-between">
              <h2 className="h2">More properties in Victoria Island, Lagos</h2>
              <div className="flex gap-x-10">
                <button className="flex h-9 w-9 rounded-full border-[2px] border-black items-center justify-center">
                  <img src="/assets/images/arrow-left.svg" alt="" />
                </button>
                <button className="flex h-9 w-9 rounded-full border-[2px] border-black items-center justify-center">
                  <img src="/assets/images/arrow-right.svg" alt="" />
                </button>
              </div>
            </div>
            <div className="grid sm:grid-cols-3 gap-y-8 gap-x-[23.22px] mt-5"></div>
          </section>
          <section className="">
            <div className="flex items-center justify-between">
              <h2 className="h2">Properties for you</h2>
              <div className="flex gap-x-10">
                <button className="flex h-9 w-9 rounded-full border-[2px] border-black items-center justify-center">
                  <img src="/assets/images/arrow-left.svg" alt="" />
                </button>
                <button className="flex h-9 w-9 rounded-full border-[2px] border-black items-center justify-center">
                  <img src="/assets/images/arrow-right.svg" alt="" />
                </button>
              </div>
            </div>
            <div className="grid sm:grid-cols-3 gap-y-8 gap-x-[23.22px] mt-5"></div>
          </section> */}

          <ImageGallery
            open={showModal}
            handleClose={() => setShowModal(false)}
          />
          <VirtualTour
            location={listing.listing.state}
            propertyName={listing.listing.name}
            open={paymentModals.virtualTour}
            handleClose={() => closePaymentModal("virtualTour")}
            submitAction={() => openPaymentModal("payWithCard")}
          />
          <PropertyVerification
            location={listing.listing.state}
            propertyName={listing.listing.name}
            open={paymentModals.propertyVerification}
            handleClose={() => closePaymentModal("propertyVerification")}
            submitAction={() => openPaymentModal("payWithCard")}
          />
          <PhysicalTour
            location={listing.listing.state}
            propertyName={listing.listing.name}
            open={paymentModals.physicalTour}
            handleClose={() => closePaymentModal("physicalTour")}
            submitAction={() => openPaymentModal("payWithCard")}
          />
          <ScheduleTour
            location={listing.listing.state}
            propertyName={listing.listing.name}
            open={paymentModals.scheduleTour}
            handleClose={() => closePaymentModal("scheduleTour")}
            submitAction={() => openPaymentModal("physicalTour")}
          />
          <PaymentSuccessful
            open={paymentModals.paymentSuccessful}
            handleClose={() => closePaymentModal("paymentSuccessful")}
            submitAction={() => navigate("/dashboard")}
          />
          <PayWithCard
            open={paymentModals.payWithCard}
            handleClose={() => closePaymentModal("payWithCard")}
          />
        </div>
      )}
    </HomeLayout>
  );
};

export default Details;

interface MoreDetailsProps {
  listing: ListingInterface;
}
const MoreDetails: React.FC<MoreDetailsProps> = ({ listing }) => {
  const {
    interiorFeatures,
    daysOnMarket,
    propertyInformation,
    miscellaneous,
    priceStatus,
    exteriorFeatures,
  } = mockData;
  const categorizedFeatures = categorizeFeatures(listing.features);
  return (
    <div className="flex flex-col gap-y-[25px]">
      <div>
        <div className="rounded-t-[9px] py-4 px-[22px] bg-primary text-white">
          <Typography variant="h5" style={{ color: "white" }}>
            Property features
          </Typography>
        </div>
        <div className="info-card">
          <div className="info-card-body">
            <div className="_section">
              <div className="_title">
                <Typography>Interior features</Typography>
              </div>
              <div className="_data">
                <ul>
                  <li>
                    <Typography>Bedrooms: {listing.bedrooms}</Typography>
                  </li>
                  <li>
                    <Typography>Bathrooms: {listing.bathrooms}</Typography>
                  </li>
                  <li>
                    <Typography>
                      Dimensions: {listing.square_footage}sqft
                    </Typography>
                  </li>
                </ul>
              </div>
            </div>
            {Object.keys(categorizedFeatures).map(
              (featureGroup: string, outdex: number) => (
                <div className="_section">
                  <div className="_title">
                    <Typography>{featureGroup}</Typography>
                  </div>
                  <div className="_data">
                    <ul>
                      {categorizedFeatures[featureGroup].map(
                        (feature: IPropertyFeatures, index: number) => (
                          <li>
                            <Typography>{feature.name}</Typography>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="rounded-t-[9px] py-4 px-[22px] bg-primary text-white">
          <Typography variant="h5" style={{ color: "white" }}>
            Property Information
          </Typography>
        </div>
        <div className="info-card">
          <div className="info-card-body">
            <div className="_section">
              <div className="_title">
                <Typography>Year Built</Typography>
                <div className="_data">
                  <ul>
                    <li>
                      <Typography>{listing.year_built}</Typography>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="_section">
              <div className="_title">
                <Typography>Property Type/Style</Typography>
                <div className="_data">
                  <ul>
                    <li>
                      <Typography>{listing.property_type}</Typography>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="_section">
              <div className="_title">
                <Typography>Price</Typography>
                <div className="_data">
                  <ul>
                    <li>
                      <Typography>{listing.price}</Typography>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="_section">
              <div className="_title">
                <Typography>Price per sqft</Typography>
                <div className="_data">
                  <ul>
                    <li>
                      <Typography>{listing.price_per_square_meter}</Typography>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
