import React from "react";
import {
  NavIconDashboard,
  NavIconDevelopers,
  NavIconDocuments,
  NavIconLogout,
  NavIconPropertyListing,
  NavIconTransactions,
  NavIconUserBuyer,
} from "../components/LayoutIcons";

export interface INavItem {
  title: string;
  icon?: JSX.Element | null;
  url: string;
  sub?: {
    title: string;
    icon?: JSX.Element | null;
    url: string;
  }[];
}
export const adminDashboardNavList: INavItem[] = [
  {
    title: "Dashboard",
    url: `/admin/dashboard`,
    icon: <NavIconDashboard />,
  },
  {
    title: "Developers",
    url: `/admin/developers`,
    icon: <NavIconDevelopers />,
  },
  {
    title: "Property Listing",
    url: `/admin/property-listings`,
    icon: <NavIconPropertyListing />,
  },
  /* { title: "Documents", url: `/admin/documents`, icon: <NavIconDocuments /> },
  {
    title: "User & Buyer",
    url: `/admin/user-n-buyer`,
    icon: <NavIconUserBuyer />,
  }, */
  {
    title: "Transactions",
    url: `/admin/transactions`,
    icon: <NavIconTransactions />,
  },
  { title: "Logout", url: `/`, icon: <NavIconLogout /> },
];

export const sellerDashboardNavList: INavItem[] = [
  {
    title: "Dashboard",
    url: `/seller/dashboard`,
    icon: <NavIconDashboard />,
  },
  {
    title: "My Listings",
    url: `/seller/listings`,
    icon: <NavIconPropertyListing />,
  },
  {
    title: "Manage Account",
    url: `/seller/manage-account`,
    icon: <NavIconUserBuyer />,
  },
  /* {
    title: "Saved Properties",
    url: `/seller/saved-properties`,
    icon: <NavIconDocuments />,
  },
  {
    title: "Application",
    url: `/seller/application`,
    icon: <NavIconUserBuyer />,
  }, */
  {
    title: "Transactions",
    url: `/seller/transactions`,
    icon: <NavIconTransactions />,
  },
  { title: "Logout", url: `/`, icon: <NavIconLogout /> },
];

export const buyerDashboardNavList: INavItem[] = [
  {
    title: "Activities",
    url: `/dashboard/activities`,
    icon: <NavIconDashboard />,
  },
  {
    title: "My Properties",
    url: `/dashboard/my-properties`,
    icon: <NavIconPropertyListing />,
  },
  {
    title: "Saved Items",
    url: `/dashboard/saved-items`,
    icon: <NavIconDocuments />,
  },
  {
    title: "Transactions",
    url: `/dashboard/transactions`,
    icon: <NavIconTransactions />,
  },
  { title: "Logout", url: `/`, icon: <NavIconLogout /> },
];
