export const BACKEND_URL = process.env.REACT_APP_ENDPOINT;
// export const BACKEND_URL = "https://coolify.homeandabroad.ng/api";

export const USER_TYPES = Object.freeze({
  ADMIN: "admin",
  SELLER: "seller",
  BUYER: "buyer",
});

export const MAX_DASHBOARD_LISTINGS_ADMIN = 3;
export const MAX_DASHBOARD_LISTINGS_SELLER = 3;

export const developerDocuments: any = {
  "Certificate of Incorporation": "certificate_of_incorporation",
  "Real Estate License": "real_estate_license",
  "Proof of Address": "proof_of_address",
  "Tax Identification Number (TIN)": "tax_identification_number",
  "Recent Tax Clearer Certificate": "tax_clearance_cert",
  "Director’s Valid ID": "directors_valid_id",
};

export const listingDocuments: any = {
  "Building Permit": "building_permit",
  "Certificate of Ownership": "certificate_of_ownership",
  "Inspection Certificates": "inspection_certificates",
  "Proof of Property Insurance": "proof_of_property_insurance",
};
