import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Navbar } from "../../commonComponents/HomeLayout";
import FeaturedProperty from "../../components/FeaturedProperty";
import FilterSection from "../../components/FilterSection";
import sortData from "../../../data/sortData.json";
import StateModal from "../../modals/StateModal";
import MyGoogleMap from "../../components/GoogleMap";
import { AppDispatch, RootState } from "../../../../store/store";
import useInput from "../../../../hooks/useInput";
import { getListings } from "../../../../apis/listings/buyer";

const Search = () => {
  const { sortOptions } = sortData;
  const { searchText } = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const searchInput = useInput(searchText || "");
  const [showStatesModal, setShowStatesModal] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const sortRef = useRef<HTMLDivElement | null>(null);
  const toggleShowSort = () => setShowSort(!showSort);

  const dispatch: AppDispatch = useDispatch();
  const listings = useSelector((state: RootState) => state.listings);
  useEffect(() => {
    dispatch(getListings());
  }, [dispatch]);

  const hideModals = (event: React.MouseEvent<HTMLDivElement>) => {
    if (showStatesModal) return;
    if (
      showFilter &&
      filterRef.current &&
      !filterRef.current.contains(event?.target as Node)
    ) {
      setShowFilter(false);
    }
    if (
      showSort &&
      sortRef.current &&
      !sortRef.current.contains(event?.target as Node)
    ) {
      setShowSort(false);
    }
  };
  return (
    <div>
      <div className="h-[100vh] flex flex-col" onClick={hideModals}>
        <Navbar />
        <div className="sm:flex items-center px-6 sm:px-8 lg:px-[50px] mt-[17px]">
          <div className="card-shadow relative h-[60px] max-w-full w-[430px] rounded-[50px]">
            <input
              type="text"
              value={searchInput.value}
              onChange={searchInput.onChange}
              className="h-full px-[18.5px] w-full text-textBlack2 text-[14px] sm:text-[16px]"
              placeholder="Address, City, Zip or Neighborhood"
            />
            <div className="h-full flex items-center justify-center absolute top-0 right-5">
              <img src="/assets/images/search.svg" alt="" />
            </div>
          </div>
          <div className="flex gap-y-3 pt-6 sm:pt-0 flex-wrap sm:contents items-center">
            <div
              onClick={() => setShowFilter(!showFilter)}
              className="flex items-center gap-x-2 ml-6 text-[16px] cursor-pointer"
            >
              <img
                src="/assets/images/filter.svg"
                className="w-[18px] h-[18px]"
                alt=""
              />
              <span>Filter</span>
            </div>
            <span className="underline shrink-0 ml-6 mr-[39px] text-[16px] cursor-pointer">
              clear all
            </span>
            <button className="filled-button w-full sm:w-fit">
              SAVE SEARCH
            </button>
          </div>
        </div>
        <div className="flex-1 sm:overflow-y-hidden w-full sm:grid sm:grid-cols-7 gap-x-8 lg:gap-x-[50px] pt-[30px] px-6 sm:px-0 sm:pr-[50px]">
          <div className="sm:col-span-3 w-full h-[60vh] sm:h-full relative">
            <MyGoogleMap />
            <div
              className={`duration-500 w-[85%] sm:w-full overflow-y-auto h-full bg-white fixed sm:absolute top-0 left-0 z-50 origin-left ${
                showFilter ? "scale-x-100" : "scale-x-0"
              }`}
              ref={filterRef}
            >
              <FilterSection
                showStateModal={() => setShowStatesModal(true)}
                closeFilter={() => setShowFilter(false)}
              />
            </div>
          </div>
          <div className="col-span-4 overflow-y-auto">
            <h2 className="h2">Real Estate and Homes for Sale</h2>
            <div className="flex relative justify-between mt-[2px] mb-[30px] text-textBlack">
              <p className="subheadings">{listings.listings.length} results</p>
              <button
                onClick={toggleShowSort}
                className="flex items-center gap-x-[9px] text-[16px]"
              >
                Sort
                <img
                  className="w-[10px] h-[5px]"
                  src="/assets/images/chevron-down.svg"
                  alt=""
                />
              </button>
              <div
                className={`w-[260px] absolute z-20 origin-right duration-500 bg-white right-0 top-10 flex flex-col p-[10px] rounded-lg card-shadow ${
                  showSort ? "scale-x-100" : "scale-x-0"
                }`}
                ref={sortRef}
              >
                {sortOptions.map((option) => (
                  <span className="py-[10px] px-4 text-[14px]">{option}</span>
                ))}
              </div>
            </div>
            <div className="grid lg:grid-cols-2 gap-10">
              {!listings.loading &&
                listings.listings.map((listing) => (
                  <FeaturedProperty key={listing.name} listing={listing} />
                ))}
              {!listings.loading && listings.listings.length === 0 && (
                <span className="body-description !text-red-700">
                  There are no listings yet
                </span>
              )}
            </div>
          </div>
        </div>
        <StateModal
          open={showStatesModal}
          handleClose={() => setShowStatesModal(false)}
        />
      </div>
    </div>
  );
};

export default Search;
