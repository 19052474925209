import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { logoutUser } from "../../../apis/accounts/auth";
import { AppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const UserDropdown = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // @ts-ignore
  const user = JSON.parse(localStorage.getItem("user"));

  const routeToDashboard = () => {
    const userType = user["user-types"][0].type;
    if (userType === "buyer") navigate("/dashboard");
    else if (userType === "seller") navigate("/seller/dashboard");
    else navigate("/admin/dashboard");
  };

  const routeToMyListings = () => {
    const userType = user["user-types"][0].type;
    if (userType === "buyer") navigate("/dashboard/my-properties");
    else if (userType === "seller") navigate("/seller/listings");
    else navigate("/admin/property-listings");
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ minWidth: "unset", padding: "9px 10px" }}
      >
        <div className="flex gap-x-2">
          <span className="capitalize text-textBlack">
            {user?.first_name} {user?.last_name[0]}
          </span>
          <img src="/assets/images/chevron-down.svg" alt="" />
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={routeToDashboard}>
          <div className="flex py-[10px] gap-x-[13px] w-[240px]">
            <img src="/assets/images/listing.svg" alt="" />
            <span>Dashboard</span>
          </div>
        </MenuItem>
        <MenuItem onClick={routeToMyListings}>
          <div className="flex py-[10px] gap-x-[13px] w-[240px]">
            <img src="/assets/images/listing.svg" alt="" />
            <span>My Listings</span>
          </div>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <div className="flex py-[10px] gap-x-[13px]">
            <img src="/assets/images/account.svg" alt="" />
            <span>My Account</span>
          </div>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <div className="flex py-[10px] gap-x-[13px]">
            <img src="/assets/images/heart.svg" alt="" />
            <span>Saved Properties</span>
          </div>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <div className="flex py-[10px] gap-x-[13px]">
            <img src="/assets/images/card.svg" alt="" />
            <span>Payment Method</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(logoutUser());
            navigate("/");

            handleClose();
          }}
        >
          <div className="flex py-[10px] gap-x-[13px]">
            <img src="/assets/images/logout.svg" alt="" />
            <span>Logout</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserDropdown;
