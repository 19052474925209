import { useEffect, useState } from "react";
import { CustomModalProps } from "../../../types";
import { Modal } from "@mui/material";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { resendUserOtp, verifyRegistrationOtp, verifyUserOtp } from "../../../apis/accounts/auth";

interface Props extends CustomModalProps {
  email: string;
}
const AccountVerification: React.FC<Props> = ({
  open,
  handleClose,
  submitAction,
  email
}) => {
  const navigate = useNavigate()
  const [otp, setOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("")
  const [otpSent, setOtpSent] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [resendOtpText, setResendOtpText] = useState("");
  const { error } = useSelector((state: RootState) => state.user);
  
  const verifyOtp = async () => {
    setErrorMsg("")
    const isSuccess = await dispatch(verifyRegistrationOtp(email, otp));
    if(isSuccess) setOtpSent(true)
    else setErrorMsg("Invalid OTP")
  };
  const resendOtp = async () => {
    setErrorMsg("")
    const isSuccess = await dispatch(resendUserOtp(email));
    isSuccess && setResendOtpText("OTP Resent");
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container">
        {!otpSent && (
          <>
            <h2 className="modal__title">Account Verification</h2>
            {resendOtpText && <span>{resendOtpText}</span>}
            {errorMsg && <span className="text-red-500 block">{errorMsg}</span>}
            <p className="subheadings mt-[5px] mb-[30px] !text-[16px] sm:!text-[18px]">
              Please verify your sign in with the OTP sent to {email}
            </p>
            <div className="flex items-center justify-between">
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                containerStyle={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
                renderInput={(props) => (
                  <input
                    {...props}
                    maxLength={1}
                    type="text"
                    className="border inline-block text-center w-[70px] border-[#E1E1E1] flex-1 h-[50px] rounded-[10px]"
                    style={{ boxShadow: "2px 2px 4px 0px #066D0E2E" }}
                  />
                )}
              />
            </div>
            <p
              onClick={resendOtp}
              className="underline mt-[5px] cursor-pointer"
            >
              Resend code
            </p>

            <button onClick={verifyOtp} className="modal__action">
              LOGIN
            </button>
          </>
        )}
        {otpSent && (
          <div className="flex flex-col items-center">
            <h2 className="modal__title">Account Verified</h2>
            <img src="/assets/images/success-icon.svg" alt="" />
            <button onClick={() => navigate("/sign-in")} className="modal__action">
              RETURN TO LOGIN
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AccountVerification;
