import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../../types";

interface Props extends CustomModalProps {
    propertyName: string
    location: string
}
const ScheduleTour: React.FC<Props> = ({ handleClose, open, submitAction, propertyName,location }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="payment-modal !text-left">
        <div className="w-[90%] mx-auto">
        <p className="payment-modal__title !text-left">
          Schedule a Physical Tour
        </p>
        <span className="block italic text-[14px] leading-[18px] w-[80%] my-[26px]">
          Please note that physical tours are only available on Monday -
          Saturday 9am-4pm (WAT)
        </span>
        <div className="flex gap-x-2">
            <p className="payment-modal__field">Property Name:</p>
            <p className="payment-modal__value">{propertyName}</p>
        </div>
        <div className="flex flex-wrap gap-x-20">
            <p className="payment-modal__field">Location:</p>
            <p className="payment-modal__value">{location}</p>
        </div>
        <div className="mt-[18px] mb-[14px] flex flex-col gap-y-2">
            <div>
                <label className="custom__label" htmlFor="">Representative's name:</label>
                <input type="text" className="custom__input" />
            </div>
            <div>
                <label className="custom__label" htmlFor="">Representative's phone number :</label>
                <input type="text" className="custom__input" />
            </div>
            <div>
                <label className="custom__label" htmlFor="">Preferred Date/Time:</label>
                <input type="datetime-local" className="custom__input" />
            </div>
        </div>
        </div>
        <button onClick={submitAction} className="filled-button w-full">Continue</button>
      </div>
    </Modal>
  );
};

export default ScheduleTour;
