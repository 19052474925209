import { Modal } from "@mui/material";
import React, { useState } from "react";
import { CustomModalProps } from "../../../types";
import useInput from "../../../hooks/useInput";

interface Props extends CustomModalProps {
  email: string;
}
const ResetPassword: React.FC<Props> = ({
  handleClose,
  open,
  submitAction,
  email,
}) => {
  const password = useInput("");
  const confirmPassword = useInput("");
  const [openGmail, setOpenGmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const updatePassword = () => {
    if (password.value !== confirmPassword.value)
      return setShowPasswordError(true);
    if(submitAction) submitAction()
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container">
        <h2 className="modal__title">Reset Password</h2>
        {!openGmail && (
          <>
            <span className="modal__subtitle">
              We've sent a link to:- {email}
            </span>
            <img className="mx-auto" src="/assets/images/loading.svg" alt="" />
            <button
              className="modal__action"
              onClick={() => setOpenGmail(true)}
            >
              OPEN GMAIL
            </button>
          </>
        )}
        {openGmail && (
          <div className="flex flex-col gap-y-4 mt-6">
            <div className="">
              <label htmlFor="password">Password</label>
              <div className="relative">
                <input
                  value={password.value}
                  onChange={password.onChange}
                  type={showPassword ? "text" : "password"}
                  className="custom__input"
                />
                {showPassword ? (
                  <img
                    onClick={togglePasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-open.svg"
                    alt=""
                  />
                ) : (
                  <img
                    onClick={togglePasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-closed.svg"
                    alt=""
                  />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="password">Confirm Password</label>
              <div className="relative">
                <input
                  value={confirmPassword.value}
                  onChange={confirmPassword.onChange}
                  type={showConfirmPassword ? "text" : "password"}
                  className="custom__input"
                />
                {showPasswordError && (
                  <span className="text-[#FF0000]">
                    Password doesn't match!
                  </span>
                )}
                {showConfirmPassword ? (
                  <img
                    onClick={toggleConfirmPasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-open.svg"
                    alt=""
                  />
                ) : (
                  <img
                    onClick={toggleConfirmPasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-closed.svg"
                    alt=""
                  />
                )}
              </div>
            </div>
            <button className="modal__action" onClick={updatePassword}>
              UPDATE PASSWORD
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ResetPassword;
